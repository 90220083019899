import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import generic from "../assets/videos/generic.mp4";
import { Link } from "react-router-dom";
import gaEventHandler from "../gaEventHandler";

const VideoModal = styled(Modal)`
    .modal-content {
        background: transparent;
        border: none;
        outline: none;
    }

    transition: all 600ms ease-in-out;
`;

const VideoPlayer = styled(ReactPlayer)`
    @media screen and (min-width: 481px) and (orientation: portrait) {
        video {
            transform: scale(1);
        }
    }
    @media screen and (min-width: 481px) and (orientation: landscape) {
        video {
            transform: translate(0%, -30%) scale(1);
        }
    }
`;

export default function OurStory() {
    const [show, setShow] = useState(false);

    return (
        <section id="ourstory" className="ourstory d-flex align-items-center">
            <div className="container">
                <div className="text-center pb-5" data-aos="zoom-in">
                    <h3>Take your digitially enabled business to the next level</h3>
                    <p>Transform the abysmal to Abliminal. Unleash your potential.</p>
                    <Link
                        className="ourstory-btn"
                        to="#"
                        onClick={(event) => {
                            setShow(true);
                            gaEventHandler.handleClick(event);
                        }}
                    >
                        Watch Our Story
                    </Link>
                </div>

                <VideoModal animation={true} show={show} onHide={() => setShow(false)}>
                    <div className="player-wrapper">
                        <VideoPlayer
                            id="overlayVideo"
                            className="react-player"
                            playing
                            muted={true}
                            controls={true}
                            url={generic}
                            onEnded={() => setShow(false)}
                            width="100%"
                            // height="200%"
                        />
                    </div>
                </VideoModal>
            </div>
        </section>
    );
}
