import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, ButtonGroup } from '@material-ui/core'

import TimeLine1 from './Timeline1'
import TimeLine2 from './Timeline2'

const Journey = () => {
    const [journey, setJourney] = useState(false)

    return (

        <StyledSection>
            <h1 className="section-title">How Does it Work?</h1>
            <ButtonGroup variant="contained" color="primary" aria-label="text primary button group">
                <Button onClick={() => setJourney(true)}>Founder</Button>
                <Button onClick={() => setJourney(false)}>Organization</Button>
            </ButtonGroup>
            {journey ? <TimeLine1 /> : <TimeLine2 />}
        </StyledSection>
    )
}

export default Journey

const StyledSection = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
// text-align center;

.section-title{
    text-align: center;
}
`;