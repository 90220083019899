import React from 'react';
import styled from 'styled-components';
import ChallengeComputer from '../../assets/images/challenge/S22SolutionsChallengeAsset2.png';
import UnderlineTextBlock from '../textblocks/UnderlineTextBlock';


const ChallengeMission = () => {
    return (
        <StyledContainer>
            <UnderlineTextBlock
                title="Our Mission"
                text="To help youth who have sustainable solutions to real life problems develop, grow and take action."
                center={true}
            />
            <img
                style={{maxWidth: "50%", height: "auto"}}
                className="img-fluid"
                src={ChallengeComputer}
                alt=""
            />
        </StyledContainer>
    );
}

export default ChallengeMission;

const StyledContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 2rem;
text-align:center;

h1{
    font-size: 1.25rem;
    font-weight: bold;
    text-align:center;
    margin-bottom: .75rem;
}

p{
    max-width: 40ch
}
`;
