import React from "react";
import LinkedinIcon from "../assets/images/logos/linkedin-brands.svg";

function Footer() {
    let year = new Date().getFullYear();

    const links = [
        // {
        //   category_name: "Useful Links",
        //   items: [
        //     { title: "Documentation", link: "/underconstruction" },
        //     { title: "Terms of Service", link: "/underconstruction" },
        //     { title: "Privacy Policy", link: "/underconstruction" },
        //   ],
        // },
        {
            category_name: "Platforms",
            items: [
                { title: "Apthenal", link: "/apthenal" },
                { title: "EyrID", link: "https://eyrid.com/" },
            ],
        },
        {
            category_name: "Legal",
            items: [
                { title: "Terms of Service", link: "/legal/terms-of-service" },
                { title: "Privacy Policy", link: "/legal/privacy-policy" },
            ],
        },
        // {
        //   category_name: "Company",
        //   items: [
        //     // { title: "Contact Us", link: "contact" },
        //     { title: "Careers", link: "/underconstruction" },
        //   ],
        // },
    ];

    return (
        <section id="footer" className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        {links.map((item, index) => {
                            return (
                                <div
                                    className="col-lg col-md-4 footer-links"
                                    key={index}
                                >
                                    <h4>{item.category_name}</h4>
                                    <ul>
                                        {item.items.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href={item.link}>
                                                        {item.title}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}

                        <div className="col-lg col-md-4 footer-contact">
                            {/* <p>
                                  Abliminal (Main Office)
                                  <br /> 100 King Street W, First Canadian Place{" "}
                                  <br />
                                  Toronto, Ontario&nbsp;&nbsp;M5X 1A9 <br />
                                  Canada
                              </p> */}
                            <div className="info">
                                <div className="address">
                                    {/* <span className="location-icon">
                                          <LocationIcon />
                                      </span> */}
                                    <div className="text">
                                        <h4>Main Office</h4>
                                        <p>
                                            First Canadian Place
                                            <br /> 100 King Street W,
                                            <br />
                                            Toronto, Ontario&nbsp;&nbsp;M5X 1A9{" "}
                                            <br />
                                            Canada
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg col-md-4 footer-contact">
                            <a
                                href="https://www.linkedin.com/company/abliminal"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {/* <input
                    id="linkedin-icon"
                    className="btn-main"
                    type="image"
                    src={LinkedinIcon}
                    alt="Linkedin Icon"
                  /> */}
                                <img
                                    id="linkedin-icon"
                                    className="social-icons"
                                    src={LinkedinIcon}
                                    alt="linkedin"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="copyright col-sm col-md-12">
                        <span>Abliminal </span>
                        <span>{year} | </span>
                        <span>All Rights Reserved</span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;
