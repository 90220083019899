import React, { useEffect, useState } from "react";
import defaultImg from "../../assets/images/blog/stockimg1.jpg";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getTopicNameById } from "./helpers";

const BlogPreview = ({
    featuredImage,
    title,
    metaDesc,
    authorData,
    dateCreated,
    topics,
    postId,
    queryType,
    id,
}) => {
    const [topicName, setTopicName] = useState("");

    useEffect(() => {
        const topic = getTopicNameById(topics);
        topic.then((data) => {
            setTopicName(data.name);
        });
    }, [topics]);

    let queryString = "";

    if (queryType && id) {
        queryString = `${queryType}/${id}/`;
    }

    return (
        <div className="col-lg-4">
            <div className="post-preview d-block">
                <div className="post-thumbnail">
                    <Link to={`/blog/post/${queryString}id/${postId}`}>
                        <img
                            className="img-fluid"
                            src={featuredImage || defaultImg}
                            alt="test img"
                        />
                    </Link>
                </div>
                <div className="post-content">
                    <div className="post-meta my-3 d-flex justify-content-between">
                        <Link
                            to={`/blog/posts/topic/${topics}`}
                            className="post-category"
                            className="post-category py-1 px-2"
                        >
                            {topicName}
                        </Link>

                        <span className="post-date">{dateCreated}</span>
                    </div>
                    <Link to={`/blog/post/${queryString}id/${postId}`}>
                        <h2 className="post-title mb-3">
                            <b>{title}</b>
                        </h2>
                    </Link>
                    <p style={{ fontSize: 13, color: "#898989" }}>{metaDesc}</p>

                    <div className="post-meta my-3 d-flex justify-content-between">
                        <div className="post-author">
                            <Link to={`/blog/posts/author/${authorData?.id}`}>
                                <strong>{authorData?.full_name}</strong>
                            </Link>
                        </div>
                        <ContinueReadingBtn
                            to={`/blog/post/id/${postId}`}
                            className="continue-reading-btn"
                        >
                            Read More&nbsp;
                            <NavigateNextIcon />
                        </ContinueReadingBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ContinueReadingBtn = styled(Link)`
    font-size: 12px;
    // width: 220px;
    background-color: rgba(255, 255, 255, 0.5);
    // padding: 10px 10px;
    color: #898989;
    // border: 5px double #898989;
    text-align: center;
    z-index: 2;
    transition: 250ms;

    &:hover {
        // border: 5px double #a9a9a9;
        color: #000e22;
    }
`;

export default BlogPreview;
