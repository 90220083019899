import React, { Component } from "react";
import SustainabilityRoadmap from "../../assets/images/resources/SustainabilityRoadmap.png";
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import ContentSection from "../textblocks/ContentSection";
import roadmapPDF from "../../assets/files/AbliminalSustainabilityRoadmap.pdf";

const ResourcesRoadmap = () => {
    const body = () => {
        return (
            <>
                <p>
                    Made for start-ups, this simple yet comprehensive guide aims to help new companies grow under the best conditions into a sustainable future.
                    Here is our roadmap broken down.
                </p>
            </>
        )
      };
    
      return (
        <ContentSection
        title="Roadmap"
        BodyText={body}
        buttonMessage="Download"
        buttonLink={roadmapPDF}
        download
        image={SustainabilityRoadmap}
        imageLarger
        orange/>
        );
}

export default ResourcesRoadmap;