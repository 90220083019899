import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import testimg from "../../assets/images/blog/stockimg1.jpg";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { getTopicNameById } from "./helpers";

const RecentPostSlide = ({
    featuredImage,
    title,
    metaDesc,
    authorData,
    dateCreated,
    topics,
    postId,
    filterValue,
}) => {
    const [topicName, setTopicName] = useState("");

    useEffect(() => {
        const topic = getTopicNameById(topics);
        topic.then((data) => {
            setTopicName(data.name);
        });
    }, [topics]);

    return (
        <div className="slide-container">
            {/* <BlogSlide image={featuredImage} className="blog-slide">
                <Overlay className="overlay"> */}

            <BlogContent className="blog-content d-flex align-items-center pt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 my-2 pb-3 featured-image">
                            <Link to={`/blog/post/id/${postId}`}>
                                <img
                                    className="img-fluid"
                                    src={featuredImage || testimg}
                                />
                            </Link>
                        </div>
                        <div className="col-lg-7">
                            <div className="post-meta my-3 d-flex justify-content-between pb-4">
                                <Link
                                    to={`/blog/posts/topic/${topics}`}
                                    className="post-category py-1 px-2"
                                >
                                    {topicName}
                                </Link>

                                <span className="post-date">
                                    <strong>{dateCreated}</strong>
                                </span>
                            </div>
                            <Link
                                to={`/blog/post/id/${postId}`}
                                className="post-title"
                            >
                                <h1>
                                    <strong>{title}</strong>
                                </h1>
                            </Link>
                            <hr
                                style={{
                                    borderTop: "5px groove #191919",
                                }}
                            />
                            <p className="meta-desc">{metaDesc}</p>
                            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                                <div className="post-author">
                                    <Link
                                        to={`/blog/posts/author/${authorData?.id}`}
                                    >
                                        <h6>
                                            <AccountCircleIcon />
                                            &nbsp;
                                            {authorData?.full_name}
                                        </h6>
                                    </Link>
                                </div>
                                <div className="d-flex">
                                    <ContinueReadingBtn
                                        to={`/blog/post/id/${postId}`}
                                        className="continue-reading-btn"
                                    >
                                        Continue Reading&nbsp;
                                        <NavigateNextIcon />
                                    </ContinueReadingBtn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlogContent>
            {/* </Overlay>
            </BlogSlide> */}
        </div>
    );
};

const BlogSlide = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${(props) => (props.image ? props.image : testimg)});
    filter: saturate(90%) opacity(95%);
`;

const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(
        120deg,
        rgba(57, 67, 89, 0.8),
        rgba(0, 0, 0, 1)
    );
`;

const BlogContent = styled.div`
    color: #222;
    vertical-align: middle;
    z-index: 100;
`;

const ContinueReadingBtn = styled(Link)`
    width: 220px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px 10px;
    color: #fff;
    // border: 5px double #898989;
    text-align: center;
    z-index: 100;
    transition: 250ms;

    &:hover {
        // border: 5px double #a9a9a9;
        color: #c9c9c9;
    }
`;

export default RecentPostSlide;
