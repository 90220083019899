import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Canada } from "../../assets/images/home-page/canada.svg"
import { ReactComponent as UK } from "../../assets/images/home-page/uk.svg"
import { ReactComponent as Australia } from "../../assets/images/home-page/australia.svg"
import { ReactComponent as USA } from "../../assets/images/home-page/usa.svg"

const LocationCard = ({ type }) => {
    switch (type) {
        case "Canada":
            return (
                <CardWrapper>
                    <CardBody color="#80ACDF">
                        <Canada />
                    </CardBody>
                    <p>Canada</p>
                </CardWrapper>

            );
            break;
        case "UK":
            return (
                <CardWrapper>
                    <CardBody color="#80ACDF">
                        <UK />
                    </CardBody>
                    <p>United Kingdom</p>
                </CardWrapper>

            );
            break;
        case "Australia":
            return (
                <CardWrapper>
                    <CardBody color="#80ACDF">
                        <Australia />
                    </CardBody>
                    <p>Australia</p>
                </CardWrapper>

            );
            break;
        case "USA":
            return (
                <CardWrapper>
                    <CardBody color="#80ACDF">
                        <USA />
                    </CardBody>
                    <p>United States of America</p>
                </CardWrapper>

            );
            break;
        default:
            break;
    }

}

export default LocationCard

const CardWrapper = styled.div`
display: grid;
place-items: center;
text-align:center;
margin: 2rem;

h1{
    color: red;
    font-size: .1rem;
}
`;

const CardBody = styled.div`
  height: 7rem;
  width: 7rem;
  margin: .5rem;
  //   height: 211px;
  //   width: 211px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.color};
  color: white;

  svg path{
      fill: white;
  }

  &:hover {
    // color: ${(props) => props.color};
    transform: scale(1.05);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.33);
    svg path {
    //   fill: ${(props) => props.color};
      //   background: red;
    }
  }
`;