import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as emailjs from "emailjs-com";
import gaEventHandler from "../gaEventHandler";
const ContactFormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name."),
    email: Yup.string().email("Email is required."),
    subject: Yup.string()
        .min(5, "Subject line too short. Minimum 5 characters.")
        .max(70, "Subject line too long. Maximum 70 characters")
        .required("Subject is required."),
    platform: Yup.string()
        .min(1, "Please select an option.")
        .required("Please select an option."),
    message: Yup.string()
        .max(500, "Message too long - Maximum 500 characters.")
        .required("Cannot be blank - Please provide a message"),
});

const ContactForm = ({ defaultPlatform }) => {
    const [confirmMessage, setConfirmMessage] = useState("");

    const initialValues = {
        name: "",
        email: "",
        subject: "",
        platform: defaultPlatform,
        message: "",
    };

    const submitForm = async (values, { resetForm }) => {
        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                "abliminal_general",
                values,
                process.env.REACT_APP_EMAILJS_USERNAME
            )
            .then(
                (result) => {
                    console.log(`Message Sent - status: ${result.text}`);
                    resetForm();
                    setConfirmMessage("Message has been sent.");
                },
                (err) => {
                    console.log(`Message Send Failed - status: ${err.text}`);
                    setConfirmMessage("Message failed to send.");
                }
            );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ContactFormSchema}
            onSubmit={submitForm}
        >
            {(formik) => {
                const {
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                    isValid,
                    dirty,
                    isSubmitting,
                } = formik;

                return (
                    <div className="col-lg-12 mt-5 mt-lg-0">
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="col-md-8 form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.name && touched.name
                                                ? "input-error form-control"
                                                : "form-control"
                                        }
                                    />

                                    {errors.name && touched.name && (
                                        <span className="error">
                                            {errors.name}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-8 mt-2 form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.email && touched.email
                                                ? "input-error form-control"
                                                : "form-control"
                                        }
                                    />
                                    {errors.email && touched.email && (
                                        <span className="error">
                                            {errors.email}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-group mt-2">
                                <input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    placeholder="Subject"
                                    value={values.subject}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.subject && touched.subject
                                            ? "input-error form-control"
                                            : "form-control"
                                    }
                                />
                                {errors.subject && touched.subject && (
                                    <span className="error">
                                        {errors.subject}
                                    </span>
                                )}
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="platform">Platform</label>
                                <select
                                    name="platform"
                                    id="platform"
                                    placeholder="Choose One:"
                                    value={values.platform}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.platform && touched.platform
                                            ? "input-error form-control"
                                            : "form-control"
                                    }
                                >
                                    <option value="">Choose One:</option>
                                    <option value="General">General</option>
                                    <option value="Apthenal">Apthenal</option>
                                    <option value="Eyrid">EyrID</option>
                                </select>
                                {errors.platform && touched.platform && (
                                    <span className="error">
                                        {errors.platform}
                                    </span>
                                )}
                            </div>
                            <div className="form-group mt-4">
                                <textarea
                                    id="message"
                                    name="message"
                                    rows="5"
                                    placeholder="Message"
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.message && touched.message
                                            ? "input-error form-control"
                                            : "form-control"
                                    }
                                ></textarea>
                                {errors.message && touched.message && (
                                    <span className="error">
                                        {errors.message}
                                    </span>
                                )}
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    disabled={
                                        !(dirty && isValid) && isSubmitting
                                    }
                                    className={
                                        dirty && isValid
                                            ? "btn-main"
                                            : "disabled-btn btn-main"
                                    }
                                    onClick={(event) => {
                                        if (dirty && isValid && isSubmitting) {
                                            gaEventHandler.handleClick(event);
                                        }
                                    }}
                                >
                                    Send Message
                                </button>
                                <p style={{ color: "#b9b9b9" }}>
                                    {confirmMessage}
                                </p>
                            </div>
                        </form>
                    </div>
                );
            }}
        </Formik>
    );
};
export default ContactForm;
