import React from "react";
import {
    Timeline,
    TimelineItem,
    TimelineOppositeContent,
    TimelineDot,
    TimelineConnector,
    TimelineSeparator,
    TimelineContent,
} from "@material-ui/lab";

// import { Typography, Paper, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import TextBlock2 from "../textblocks/TextBlock";

const TimeLine1 = () => {
    return (
        // <TimelineSection>
        <TimelineContainer>
            <TimeLine align="alternate">
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Taking Prototype to Product</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Honing Problem Statment and Vision</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Understanding Founder Strengths</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Bring Solution to Market</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Broader Adoption</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Refine Team</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Develop Brand</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Customer Retention</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>
            </TimeLine>

            <TextBlock2
                title="Founder Journey"
                text="You want to grow your business while maintaining your own vision. You have built a prototype, but you don't know how you are going to get it to market.  We are your product growth guides.  We help take your solution to clients with the proven 
                    experience to ensure you are supported every step of the way."
            />
        </TimelineContainer>
        // </TimelineSection>
    );
};

export default TimeLine1;

const TimelineContainer = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    justify-content: center;
    padding: 2rem;
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        padding: 0.5rem;
    }
`;

const TimelineSection = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
text-align center;
`;

const Page = styled.div`
    padding: 6px 16px;
    text-align: center;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    //background-color: lightgrey;
    transition: ease-in 0.3s;

    @media (max-width: 768px) {
        h5 {
            font-size: 10px;
        }
    }

    &:hover {
        background-color: #5f8bff;
        color: white;
        transform: scale(1.2);
    }
`;

const TimeLine = styled(Timeline)`
    transform: scale(0.8);
    width: 100%;
    justify-self: center;
`;
