import React, { Component } from "react";

import SuccessStoriesCarousel from "../../components/SuccessStoriesCarousel";
import { apthenalStoriesData } from "../../data/SuccessStoriesData";
import apthenalscreen from "../../assets/images/apthenalscreen4.png";

export default class ApthenalSuccessStories extends Component {
  render() {
    return (
      <section id="apthenal-stories" className="apthenal-stories">
        <div className="container">
          <div className="row ">
            <div className="col-lg-5 d-flex justify-content-center">
              <div className="home_left_img">
                <img className="img-fluid" src={apthenalscreen} alt="" />
              </div>
            </div>
            <div className="section-title col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
              <h3>Stories from across the industries</h3>
              {/* <p>
                Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
                adipisci expedita at voluptas atque vitae autem.
              </p> */}
            </div>
          </div>

          <SuccessStoriesCarousel data={apthenalStoriesData} />
        </div>
      </section>
    );
  }
}
