import React, { Component } from "react";
import worldmap from "../../assets/images/geographies3.svg";
import canada from "../../assets/images/canada.svg";
import uk from "../../assets/images/uk.svg";
import australia from "../../assets/images/australia.svg";
import usa from "../../assets/images/usa.svg";

export default class Geographies extends Component {
    geographies = [
        {
            country: "Canada",
            img_src: canada,
        },
        {
            country: "United Kingdom",
            img_src: uk,
        },
        {
            country: "Australia",
            img_src: australia,
        },
        {
            country: "United States of America",
            img_src: usa,
        },
    ];
    render() {
        return (
            <section id="geographies" className="geographies">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="section-header wow fadeIn"
                                data-wow-duration="1s"
                            >
                                <h3 className="section-title">
                                    From around the world
                                </h3>
                                <span className="section-divider"></span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row list-countries">
                                <div className="col-lg-3 col-md-3 col-sm-3">
                                    <div className="country">
                                        <span className="img">
                                            <img src={canada} alt="Canada" />
                                        </span>
                                        <span className="text" id="can">
                                            Canada
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3">
                                    <div className="country">
                                        <span className="img">
                                            <img src={usa} alt="USA" />
                                        </span>
                                        <span className="text" id="usa">
                                            United States <br />
                                            of America
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3">
                                    <div className="country">
                                        <span className="img">
                                            <img src={uk} alt="UK" />
                                        </span>
                                        <span className="text" id="uk">
                                            United <br /> Kingdom
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3">
                                    <div className="country">
                                        <span className="img">
                                            <img
                                                src={australia}
                                                alt="Australia "
                                            />
                                        </span>
                                        <span className="text" id="aus">
                                            Australia
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <img
                                src={worldmap}
                                id="world-map"
                                alt="World map"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
