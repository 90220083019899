import React from "react";
import {
    Timeline,
    TimelineItem,
    TimelineOppositeContent,
    TimelineDot,
    TimelineConnector,
    TimelineSeparator,
    TimelineContent,
} from "@material-ui/lab";

// import { Typography, Paper, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import TextBlock2 from "../textblocks/TextBlock";

const TimeLine2 = () => {
    return (
        // <TimelineSection>
        <TimelineContainer>
            <TimeLine align="alternate">
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Identified Painpoints</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Solution Validated</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Build the Solution</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Fix the Problem</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Bring Solution to Market</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Create Stand-Alone Entity</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Raise Series 'A' Finance</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Support Growth to Series 'B'</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Page>
                            <h5>Self-Sustaining</h5>
                        </Page>
                    </TimelineContent>
                </TimelineItem>
            </TimeLine>

            <TextBlock2
                title="Organization Journey"
                text="We help you solve a problem and make it profitable. For the same cost it would take to solve an existing issue, we help to develop a peer-validated product. 
                    You build a sustainable solution while creating intellectual property that generates revenue and is independently supported."
            />
            {/* <h1>How it Works</h1> */}
            {/* <p>This is how it works</p> */}
        </TimelineContainer>
        // {/* </TimelineSection> */ }
    );
};

export default TimeLine2;

const TimelineContainer = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    justify-content: center;
    padding: 2rem;
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        padding: 0.5rem;
    }
`;

const TimelineSection = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
text-align center;
`;

const Page = styled.div`
    padding: 6px 16px;
    text-align: center;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    //background-color: lightgrey;
    transition: ease-in 0.3s;

    @media (max-width: 768px) {
        h5 {
            font-size: 10px;
        }
    }

    &:hover {
        background-color: #5f8bff;
        color: white;
        transform: scale(1.2);
    }
`;

const TimeLine = styled(Timeline)`
    transform: scale(0.8);
    width: 100%;
    justify-self: center;
`;
