import React, { Component } from "react";
import { Link as ReactScroll } from "react-scroll";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import GlobePerson from "../../assets/images/resources/GlobePerson.png";
import { useMediaQuery } from 'react-responsive';

const ResourcesLanding = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

        return (
            <section style={{backgroundColor: "white"}} className="home_banner_area">
                <div className="banner_inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div style={isMobile ? {alignItems: "center", textAlign: "center", paddingTop: "100px"} : {}} className="banner_content ">
                                    <h1 style={{fontWeight: "500", fontSize: "60px"}} className="animate__animated animate__fadeInDown">
                                        Our Sustainable Resources
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div style={{paddingTop: "100px"}}>
                                    <img
                                        className="img-fluid"
                                        src={GlobePerson}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default ResourcesLanding;
