import React from "react";
// import Collaborators from "../components/Collaborators";
import OurStory from "../components/OurStory";
// import Footer from "../components/Footer";
import Geographies from "../components/home/Geographies";
import Hero from "../components/Hero";
import Industries from "../components/home/Industries";
// import Navbar from "../components/Navbar";
// import Sidebar from "../components/Sidebar";
import Solutions from "../components/home/Solutions";
// import Contact from "../components/Contact";
import Supporters from "../components/Supporters";
import Innovation from "../components/home/Innovation";

import { motion } from "framer-motion";
import { animationHome } from "../animations/animate";
// import { Helmet, HelmetProvider } from "react-helmet-async";
// import CookieConsent from "react-cookie-consent";
import Logo from "../assets/images/logos/NavLogo2.svg";
import Layout from "../hocs/Layout";
import HeroNC from "../components/home/HeroNC";
// import Problems from "../components/home/Problems";
import FO from "../components/home/FO";
import Painpoints from "../components/home/Painpoints"
import InfiniteLogos from "../components/home/InfiniteLogos";
import Steps from "../components/home/Steps";
import Locations from "../components/home/Locations";
import Journey from "../components/home/Journey";
import Support from "../components/home/Support";

const Home = () => {
  const metaContent =
    "Empowering Risk Takers & Dream Makers. Unify IAM & cyber risk tools across your digital value chain. Actionable, multi-domain analytics on a single platform.";
  const canonicalLink = "http://www.abliminal.com/";
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={animationHome}
      transition={{ duration: 0.3 }}
    >
      <Layout
        hasFooter
        pageTitle={`ABLIMINAL: Digital Identity | Cyber Risk Management Platform`}
        logo={Logo}
        metaContent={metaContent}
        metaCanonicalLink={canonicalLink}
        page="home"
      >
        <HeroNC />
        <FO />
        <Painpoints />
        <Steps />
        <Journey />
        <Locations />
        <Support />
        {/* <Supporters /> */}
        <InfiniteLogos />
      </Layout>
    </motion.div>
  );
};

export default Home;
