import React from 'react'
import styled, { keyframes } from 'styled-components'
import TextBlock from '../textblocks/TextBlock'
import LogosStripe from './LogoStripe'
// import { ReactComponent as Stripe } from '../../../assets/logos/LogoStripe1.svg'

const InfiniteLogos = () => {
    return (

        <StyledContainer>
            <TextBlock
                title="Advisory Board"
                text="Our Board of Advisors bring experience from organizations such as:"
                center={true}
            />
            <StyledContainer2>
                <div className="container">
                    <div className="wrapper1">
                        <LogosStripe ClassName="logo" />
                        <LogosStripe ClassName="logo" />
                    </div>
                </div>

                <div className="container">
                    <div className="wrapper2">
                        <LogosStripe ClassName="logo" />
                        <LogosStripe ClassName="logo" />
                    </div>
                </div>
            </StyledContainer2>
        </StyledContainer>

    )
}

export default InfiniteLogos

const StyledContainer = styled.div`
// background-color: lightgrey;
text-align: center;
padding: 6rem 0;
`;

const slide = keyframes`
from {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
to {
    -webkit-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
}
`;

const StyledContainer2 = styled.div`
// height: 100vh;
display:flex;
flex-direction: column;
justify-content:center;
align-item:center;
overflow: hidden;
.logo{
	display: inline-block;
}
.container {
	// background-color: #d9fff8;
	white-space: nowrap;
	display: block;
}
.wrapper1 {
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	animation: ${slide} 45s linear 0ms infinite normal;
}
.wrapper2 {
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	animation: ${slide} 50s linear 0ms infinite normal;
    .logo-container{
        flex-direction: row-reverse;
    }
}
`;