import React from "react";
import styled from "styled-components";
import { ReactComponent as Lightbulb } from "../../assets/images/card-icons/Lightbulb.svg";
import { ReactComponent as Plant } from "../../assets/images/card-icons/Plant.svg";
import { ReactComponent as Gear } from "../../assets/images/card-icons/Gear.svg";
import { ReactComponent as Coins } from "../../assets/images/card-icons/Coins.svg";
import { ReactComponent as PieGraph } from "../../assets/images/card-icons/PieGraph.svg";
import { ReactComponent as Cost } from "../../assets/images/card-icons/Cost.svg";
import { ReactComponent as Bandaid } from "../../assets/images/card-icons/Bandaid.svg";

import { ReactComponent as Canada } from "../../assets/images/home-page/canada.svg"
import { ReactComponent as UK } from "../../assets/images/home-page/uk.svg"
import { ReactComponent as Australia } from "../../assets/images/home-page/australia.svg"
import { ReactComponent as USA } from "../../assets/images/home-page/usa.svg"

const CardCircle = ({ type }) => {
    switch (type) {
        case "Insights":
            return (
                <CardBody color="#FFB240">
                    <Lightbulb />
                    <p>Insights</p>
                </CardBody>
            );
            break;
        case "Investment":
            return (
                <CardBody color="#40C1B7">
                    <Plant />
                    <p>Investment</p>
                </CardBody>
            );
            break;
        case "Innovation":
            return (
                <CardBody color="#7FA0DD">
                    <Gear />
                    <p>Innovation</p>
                </CardBody>
            );
            break;
        case "Income":
            return (
                <CardBody color="#FF4A51">
                    <Coins />
                    <p>Income</p>
                </CardBody>
            );
            break;
        case "Resources":
            return (
                <CardBody color="#970000">
                    <PieGraph />
                    <p>Lack Resources</p>
                </CardBody>
            );
            break;
        case "Cost":
            return (
                <CardBody color="#970000">
                    <Cost />
                    <p>Cost Center</p>
                </CardBody>
            );
            break;
        case "TempFix":
            return (
                <CardBody color="#970000">
                    <Bandaid />
                    <p>Temporary Fix</p>
                </CardBody>
            );
            break;
        case "Canada":
            return (
                <CardBody color="#80ACDF">
                    <Canada />
                </CardBody>
            );
            break;
        case "UK":
            return (
                <CardBody color="#80ACDF">
                    <UK />
                </CardBody>
            );
            break;
        case "Australia":
            return (
                <CardBody color="#80ACDF">
                    <Australia />
                </CardBody>
            );
            break;
        case "USA":
            return (
                <CardBody color="#80ACDF">
                    <USA />
                </CardBody>
            );
            break;
        default:
            break;
    }

    return (
        <CardBody>
            <Lightbulb />
            <p>Default</p>
        </CardBody>
    );
};

export default CardCircle;

const CardBody = styled.div`
  height: 10rem;
  width: 10rem;
  margin: .5rem;
  //   height: 211px;
  //   width: 211px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.color};
  color: white;

  svg path{
      fill: white;
  }

  &:hover {
    // color: ${(props) => props.color};
    transform: scale(1.05);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.33);
    svg path {
    //   fill: ${(props) => props.color};
      //   background: red;
    }
  }
`;