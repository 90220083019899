import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Founder } from '../../assets/images/home-page/Analyst-Sit.svg'
import { ReactComponent as Organization } from '../../assets/images/home-page/Analyst-Team.svg'
import TextBlock from '../textblocks/TextBlock'


const FoundOrgCard = ({ variant }) => {
    switch (variant) {
        case "Founder":
            return (
                <StyledContainer>
                    <Founder />
                    <TextBlock
                        title="Founder"
                        text="You have a prototype and you work on it in your spare time. You want to kick it off,
                    but you need a team to help with skill and resource gaps"
                        center={true}
                    />
                    {/* <h4>Founder</h4>
                    <p>You have a prototype and you work on it in your spare time. You want to kick it off,
                        but you need a team to help with skill and resource gaps.</p> */}
                </StyledContainer>
            );
            break;
        case "Organization":
            return (
                <StyledContainer>
                    <Organization />
                    <TextBlock
                        title="Organization"
                        text="You have intellectual property that is difficult to maintain and you
                    think it could serve the broader market"
                        center={true}
                    />
                    {/* <h4>Organization</h4>
                    <p>You have intellectual property that is difficult to maintain and you
                        think it could serve the broader market.</p> */}
                </StyledContainer>
            );
            break;
        default:
            break;

            return (
                <StyledContainer>
                    <Founder />
                    <h4>Founder</h4>
                    <p>You have a prototype and you work on it in your spare time. You want to kick it off,
                        but you need a team to help with skill and resource gaps.</p>
                </StyledContainer>
            );
    }
}

export default FoundOrgCard

const StyledContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 2rem;
text-align:center;

h1{
    font-size: 1.25rem;
    font-weight: bold;
    text-align:center;
    margin-bottom: .75rem;
}

p{
    max-width: 40ch
}
`;
