import React, { Component } from "react";
import { Link as ReactScroll } from "react-scroll";

export default class Hero extends Component {
  data = [
    [
      {
        title: "Empowering Better Decisions",
        desc: "We are the connective tissue of your digitally enabled business. Looking for innovative ways to use your existing technology, or transforming it for your next strategic initiative? We provide you the platforms you need to accelerate and excel.",
        link: "ourstory",
      },
    ],
    [
      {
        title: "Platforms for Data Driven Decisions",
        desc: "We provide you what you need to make the right decisions. Take your business to the next level with our platforms Apthenal and EryID.",
        link: "solutions",
      },
    ],
    [
      {
        title: "Serving Industries Around the Globe",
        desc: "Global Insights, Local Team. We problem solve together at our locations around the world - Canada, USA, UK, and Australia.",
        link: "industries",
      },
    ],
  ];

  render() {
    return (
      <section id="hero">
        <div className="hero-container">
          <div
            id="heroCarousel"
            data-bs-interval="5000"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <ol
              className="carousel-indicators"
              id="hero-carousel-indicators"
            ></ol>

            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h1 className="animate__animated animate__fadeInDown">
                      {this.data[0][0].title}
                    </h1>
                    <p className="animate__animated animate__fadeInUp">
                      {this.data[0][0].desc}
                    </p>
                    <div>
                      <ReactScroll
                        to={this.data[0][0].link}
                        className="btn-main"
                        smooth={true}
                        duration={400}
                      >
                        Learn More
                      </ReactScroll>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="carousel-item"
                // style="background: url(assets/img/slide/slide-2.jpg);"
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h1 className="animate__animated animate__fadeInDown">
                      {this.data[1][0].title}
                    </h1>
                    <p className="animate__animated animate__fadeInUp">
                      {this.data[1][0].desc}
                    </p>
                    <ReactScroll
                      to={this.data[1][0].link}
                      className="btn-main"
                      smooth
                      duration={400}
                    >
                      Learn More
                    </ReactScroll>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h1 className="animate__animated animate__fadeInDown">
                      {this.data[2][0].title}
                    </h1>
                    <p className="animate__animated animate__fadeInUp">
                      {this.data[2][0].desc}
                    </p>
                    <div>
                      <ReactScroll
                        to={this.data[2][0].link}
                        className="btn-main"
                        smooth
                        duration={400}
                      >
                        Learn More
                      </ReactScroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a
              className="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon icofont-simple-left"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>

            <a
              className="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon icofont-simple-right"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
    );
  }
}
