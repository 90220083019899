import React from "react";
import styled from "styled-components";
import CardCircle from "../cards/CardCircle";
import TextBlock from "../../components/textblocks/TextBlock";

const Innovation = () => {
  return (
    <section>
      <BlockContainer>
        <TextBlock
          title="Where Innovation Incubates"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu, morbi nec ut convallis arcu faucibus at gravida in. 
                    Leo at luctus massa tortor sed non. In ullamcorper odio sapien pharetra scelerisque facilisi. Duis pulvinar purus at eget."
        />

        <CardContainer>
          <CardColumn1>
            <CardCircle type="Insights"></CardCircle>
            <CardCircle type="Investment"></CardCircle>
          </CardColumn1>

          <CardColumn2>
            <CardCircle type="Innovation"></CardCircle>
            <CardCircle type="Income"></CardCircle>
          </CardColumn2>
        </CardContainer>
      </BlockContainer>

    </section>

  );
};

export default Innovation;

const CardColumn1 = styled.div`
  display: grid;
  // grid-gap: 1rem;
  grid-template-rows: repeat(2, 1fr);
`;

const CardColumn2 = styled.div`
  display: grid;
  // grid-gap: 1rem;
  grid-template-rows: repeat(2, 1fr);
  padding-top: 5rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CardContainer = styled.div`
// width: 80%;
align-self: center;
  display: grid;
  // grid-gap 1rem;
  grid-template-columns: repeat(2, 1fr);
//   grid-template-rows: repeat(2, 1fr);
  place-items: center;
  padding: 0 2rem;
  @media (max-width: 481px) {
    display: flex;
    flex-direction: column;
  }  
`;

const BlockContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;