import { React } from "react";
import { AnimatePresence } from "framer-motion";
import ScrollToTop from "./ScrollToTop";
import Routes from "./Routes";
import CookieConsentNotification from "./CookieConsentNotification";

const App = () => {
    return (
        <div className="App" id="App">
            <AnimatePresence>
                <ScrollToTop>
                    <CookieConsentNotification />
                    <Routes />
                </ScrollToTop>
            </AnimatePresence>
        </div>
    );
};

export default App;
