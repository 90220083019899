import React, { Component } from "react";
import BarrierGirl from "../../assets/images/challenge/BarrierGirl.png";
import styled from 'styled-components';
import ContentSection from "../textblocks/ContentSection";
import TermsAndConditions from "../../assets/files/AbliminalSolutionsChallengeTermsConditions.pdf";

const ChallengeRegister = () => {

    const body = () => {
        return (
            <>
                <p>
                    Break barriers with us through this challenge. If you are interested - register today. FREE for all youth.
                </p>
                <p>
                    If you need accommodations, <ResourceLink href="mailto:solutions@abliminal.com?subject=Accommodation Request ">do let us know</ResourceLink> and we will do our best to help!
                </p>
                <ResourceLink href={TermsAndConditions} download>
                    Please read our terms and conditions before you register.
                </ResourceLink>
            </>
        )
      };

    return (
        <ContentSection
            title="Are You Ready?"
            BodyText={body}
            buttonMessage="Register"
            buttonLink="https://forms.office.com/Pages/ResponsePage.aspx?id=CDYXsbBji0-duz2-PV1g1Tmt4d7f1uFItArBAi3kN1tUN0xLWE9QWlNDTU9LUUUzRjFKRDI1SDBTVi4u"
            image={BarrierGirl}
            rightAlign/>
    );
}

export default ChallengeRegister;

const ResourceLink = styled.a`
color: black;
cursor: pointer;
text-decoration-line: underline;

&:hover{
    color: #111e44;
    text-decoration-line: none;
}
`;