import React, { Component } from "react";
import { Link as ReactScroll } from "react-scroll";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import challengeMainLogo from "../../assets/images/challenge/S22SolutionsChallengeAsset1.png";
import { useMediaQuery } from 'react-responsive';

const ChallengeLanding = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

        return (
            <section className="home_banner_area">
                <div className="banner_inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div style={isMobile ? {alignItems: "center", textAlign: "center"} : {}} className="banner_content ">
                                    <h1 className="animate__animated animate__fadeInDown">
                                        Abliminal's Solutions Challenge
                                    </h1>
                                    <h5 className="animate__animated animate__fadeInDown">
                                        We see a world where everyone has the opportunity to develop their sustainable cybersecurity solution.
                                    </h5>
                                    <h5 className="animate__animated animate__fadeInDown">
                                        Sign up for the Abliminal Solutions Challenge and get ready to use tech to work towards the UN Sustainble Development Goals!
                                    </h5>
                                    <a
                                        style={{marginLeft: "0px"}}
                                        className="btn-main"
                                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=CDYXsbBji0-duz2-PV1g1Tmt4d7f1uFItArBAi3kN1tUN0xLWE9QWlNDTU9LUUUzRjFKRDI1SDBTVi4u"
                                    >
                                        Register Now
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div>
                                    <img
                                        className="img-fluid"
                                        src={challengeMainLogo}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default ChallengeLanding;
