import React from "react";
import { slide as BurgerMenu } from "react-burger-menu";
import SubMenu from "../components/Submenu";
import { navData } from "../data/NavData";
import LinkedinIcon from "../assets/images/logos/linkedin-brands.svg";

function Sidebar() {
    return (
        <>
            <BurgerMenu
                right
                outerContainerId={"container"}
                className="sidebar"
                disableAutoFocus
                styles={{
                    bmCrossButton: {
                        transform: "scale(1.25) translate(-50%, 50%)",
                    },
                }}
            >
                <nav>
                    {/* <div className="d-flex flex-column justify-content-center">
                        <div className="sidebar-logo">
                            <Link exact to="/home">
                                <img src={Logo} alt="Abliminal Logo" />
                            </Link>
                        </div>
                    </div> */}

                    {navData.map((item, index) => {
                        return <SubMenu item={item} key={index} />;
                    })}
                </nav>
                <a
                    href="https://www.linkedin.com/company/abliminal"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        id="linkedin-icon"
                        className="social-icons"
                        src={LinkedinIcon}
                        alt="linkedin"
                        style={{ maxWidth: "50px" }}
                    />
                </a>
            </BurgerMenu>
        </>
    );
}

export default Sidebar;
