// import ReactGA from "react-ga";

const gaEventHandler = {
    handleClick: (e) => {
        // console.log(e);

        let event = {
            category: "",
            action: "",
            label: "",
        };

        const tag = e.target.nodeName.toLowerCase();
        const classname = e.target.className;
        const source = e.target.baseURI;
        let dest = e.target.href;
        let title = e.target.innerHTML;
        let category = "";

        if (tag === "a") {
            category = "Navigation via Link";
        }

        if (tag === "button") {
            category = "Navigation via Button";
            if (title === "Send Message") {
                category = "Form Submission";
            }
        }

        if (tag === "img") {
            category = "Navigation via Image";
            if (!title) {
                title = e.target.alt;
            }
            if (!dest) {
                dest = e.target.parentNode.origin;
            }
        }

        event.category = `${category}`;
        event.action = `Clicked: "${title}"; From: "${source}"; To: "${dest} `;
        event.label = `Element className="${classname}`;

        // ReactGA.event(event);

        window.gtag("event", event.category, event);
    },
};

export default gaEventHandler;
