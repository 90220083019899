import React from "react";
// import Collaborators from "../components/Collaborators";
// import Navbar from "../navigation/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ApthenalFeatures from "../components/Apthenal/ApthenalFeatures";
import ApthenalLanding from "../components/Apthenal/ApthenalLanding";
import ApthenalCollaborators from "../components/Apthenal/ApthenalCollaborators";
import ApthenalSuccessStories from "../components/Apthenal/ApthenalSuccessStories";
import RequestDemo from "../components/RequestDemo";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../hocs/Layout";
import Logo from "../assets/images/logos/NavLogo2.svg";

const Apthenal = () => {
    const metaContent = `Transform Uncertainty into Value. Unify cyber risk standards across multiple verticals. Bootstrap digital M&A risk universe with actionable insights.`;
    const canonicalLink = `http://www.abliminal.com/apthenal`;

    return (
        <>
            <Layout
                hasFooter
                pageTitle={`Apthenal: Bootstrap Cyber Risk Management Platforms`}
                logo={Logo}
                metaContent={metaContent}
                metaCanonicalLink={canonicalLink}
                page="apthenal"
            >
                <ApthenalLanding />
                <ApthenalCollaborators />
                <ApthenalFeatures />
                <ApthenalSuccessStories />
            </Layout>
        </>
    );
};

export default Apthenal;
