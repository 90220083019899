import React, { Component } from "react";
import FlowerGirl from "../../assets/images/challenge/S22SolutionsChallengeAsset3.png";
import styled from 'styled-components';
import ContentSection from "../textblocks/ContentSection";
import { useMediaQuery } from 'react-responsive';

const ChallengeProgramStructure = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

  const body = () => {
    return (
        <>
            <p>
              At Abliminal, we recognize that many people who identify as neurodivergent face accessibility issues. As an incubator company working within the cybersecurity field, we believe that we can provide the necessary support to help others (like neurodiverse females) thrive in a start-up environment.
            </p>

            <h5>Guidelines</h5>
            <p>
              We are looking for teams of youth (between the ages of 18 - 30 years). We are particularly interested in supporting those who identify as female and/or neurodivergent.
            </p>

            <h5>Rules</h5>
            <p>
              The idea/proposal must solve at least one of the <ResourceLink href="https://sdgs.un.org/goals">17 UN Sustainable Goals.</ResourceLink>
            </p>
            <ul style={isMobile ? {listStyleType: "none"} : {}}>
              <li>
                It must be a digital solution/product
              </li>
              <li>
                Must be related to cybersecurity
              </li>
              <li>
                Must have a plan to generate revenue
              </li>
          </ul>
            <h5>Contact Us</h5>
            <p>
              If you have any questions or concerns, you can easily reach us at <ResourceLink href="mailto:solutions@abliminal.com?subject=Abliminal Solutions Concerns">solutions@abliminal.com</ResourceLink> - please title the email as "Abliminal Solutions Concerns"
            </p>
        </>
    )
  };

  return (
      <ContentSection
      title="Program Structure"
      BodyText={body}
      image={FlowerGirl}/>
  );
}

export default ChallengeProgramStructure;


const ResourceLink = styled.a`
color: black;
cursor: pointer;
text-decoration-line: underline;

&:hover{
    color: #111e44;
    text-decoration-line: none;
}
`;