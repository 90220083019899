import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BlogNavigation = () => {
    const NavDropdown = () => {
        return (
            <li className="nav-item dropdown dropdown-hover position-static">
                <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                >
                    Topics
                </a>
                <div
                    className="dropdown-menu w-80"
                    aria-labelledby="navbarDropdown"
                    style={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        marginTop: -8,
                    }}
                >
                    <div className="container">
                        <div className="row my-4">
                            <div className="col-md-12 mb-3 mb-lg-0">
                                <div className="list-group list-group-flush">
                                    <Link
                                        to=""
                                        className="list-group-item list-group-item-action"
                                    >
                                        Lorem ipsum
                                    </Link>
                                    <a
                                        href=""
                                        className="list-group-item list-group-item-action"
                                    >
                                        Dolor sit
                                    </a>
                                    <a
                                        href=""
                                        className="list-group-item list-group-item-action"
                                    >
                                        Amet consectetur
                                    </a>
                                    <a
                                        href=""
                                        className="list-group-item list-group-item-action"
                                    >
                                        Cras justo odio
                                    </a>
                                    <a
                                        href=""
                                        className="list-group-item list-group-item-action"
                                    >
                                        Adipisicing elit
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    };

    return (
        <Navigation className="blog-nav d-flex align-items-end">
            <div
                className="container"
                style={{
                    zIndex: 100,
                    color: "#fff",
                    // border: "2px solid #fff",
                }}
            >
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <div className="container-fluid">
                        <button
                            className="navbar-toggler px-0"
                            type="button"
                            data-mdb-toggle="collapse"
                            data-mdb-target="#main-nav"
                            aria-controls="main-nav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="main-nav">
                            <ul className="navbar-nav me-auto ps-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        Regular link
                                    </a>
                                </li>
                                <NavDropdown />
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </Navigation>
    );
};

const Navigation = styled.div`
    background: #100e22;
    height: 20vh;
    z-index: 1000;
`;

export default BlogNavigation;
