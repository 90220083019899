import React, { useState, useEffect } from "react";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import { Link } from "react-router-dom";
import up_icon from "../assets/images/up-arrow.svg";
import defaultLogo from "../assets/images/logos/NavLogo2.svg";
import BlogNavbar from "../components/hubspot-blog/BlogNavbar";
import "./navbar.css";

const Navigation = ({ logo, page, ...props }) => {
    const [change, setChange] = useState(false);
    const changePosition = window.innerHeight * 0.8;
    let position = useWindowScrollPosition();
    let headerColor = change ? "rgba(50, 53, 66, 0.5)" : "transparent";
    if (position.y > changePosition && !change) {
        setChange(true);
    }
    if (position.y <= changePosition && change) {
        setChange(false);
    }
    if (props.colorTheme === "dark") {
        headerColor = "rgba(50, 53, 66, 0.3)";
    }
    let headerStyle = {
        backgroundColor: headerColor,
        height: change ? "75px" : "80px",
        padding: change ? "10px" : "20px",
        opacity: "1",
        transition: "250ms ease",
    };
    let backTopBtnStyle = {
        display: change ? "block" : "none",
        scrollBehavior: "smooth",
    };
    const returnTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <header className="header" id="header" style={headerStyle}>
            <div className="navbar-wrapper d-flex justify-content-center">
                <Link
                    to="#"
                    className="back-to-top"
                    style={backTopBtnStyle}
                    onClick={returnTop}
                >
                    <img
                        src={up_icon}
                        id="back-to-top-icon"
                        alt="back-to-top"
                    />
                </Link>
                <Link to={`/`} className="navbar-logo">
                    <img src={logo || defaultLogo} alt="Abliminal Logo" />
                </Link>
                <nav className="nav-menu">
                    <ul>
                        {/* <li>
                              <Link to="#">About Us</Link>
                          </li> */}
                        {/* <li className="drop-down">
                              <a href="#">Solutions</a>
                              <ul id="resources-dropdown">
                                  <li>
                                      <a href="/eyrid">EyrID</a>
                                  </li>
                                  <li>
                                      <a href="/apthenal">Apthenal</a>
                                  </li>
                              </ul>
                          </li>
                          <li className="drop-down">
                              <a href="#">Resources</a>
                              <ul id="resources-dropdown">
                                  <li>
                                      <a href="/underconstruction">
                                          Documentation
                                      </a>
                                  </li>
                                  <li>
                                      <a href="/underconstruction">
                                          Customer Stories
                                      </a>
                                  </li>
                              </ul>
                          </li> */}
                    </ul>
                </nav>
                <div className="header_actions">
                    <Link to="/underconstruction" className="action-links">
                        Log in
                    </Link>
                    <Link
                        to="/underconstruction"
                        className="action-links btn-main"
                    >
                        Get Started
                    </Link>
                    {/* <a
                          className="action-links btn-main"
                          id="contact-linkedin"
                          href="https://www.linkedin.com/company/abliminal"
                          target="_blank"
                          rel="noreferrer"
                      >
                          Contact Us <LinkedInIcon />
                      </a> */}
                </div>
            </div>
        </header>
    );
};

export default Navigation;
