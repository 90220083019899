import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
    faEye,
    faChartBar,
    faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";

export default class ApthenalFeatures extends Component {
    render() {
        return (
            <section id="apthenal-features" className="apthenal-features">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i>
                                        <FontAwesomeIcon icon={faEye} />
                                    </i>
                                </div>
                                <h4 className="title">Assess Risk</h4>
                                <p className="description">
                                    Providing unique risk insights for those
                                    looking to acquire, assess, or sell an
                                    organization and/or its subsidiaries.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i>
                                        <FontAwesomeIcon icon={faChartBar} />
                                    </i>
                                </div>
                                <h4 className="title">
                                    Improve Decision Making
                                </h4>
                                <p className="description">
                                    Apthenal supports the acquisition and
                                    subsidiary lifecycle, its technology
                                    implications, integrations, and remediation
                                    decision making when adding or removing
                                    those entities within your portfolio.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i>
                                        <FontAwesomeIcon
                                            icon={faTachometerAlt}
                                        />
                                    </i>
                                </div>
                                <h4 className="title">Train Your Team</h4>
                                <p className="description">
                                    Your job is unique, but is your training?
                                    Targeted phishing emails, data handling,
                                    platform training are all different
                                    depending on your organizational role.
                                    Apthenal assists in role-based training
                                    focused on specific lines of business and
                                    the challenges they face.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
