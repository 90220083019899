import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
    faChartLine,
    faPeopleCarry,
    faHandshake,
} from "@fortawesome/free-solid-svg-icons";

const ResourcesOutcome = () => {
        return (
            <section id="resource-outcomes" className="resource-outcomes">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i>
                                        <FontAwesomeIcon icon={faChartLine} />
                                    </i>
                                </div>
                                <h4 className="title">Increase Profits</h4>
                                <p className="description">
                                    Studies by Harvard Business Review have shown proof that sustainable companies
                                    were consistent in generating above average growth & profits.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i>
                                        <FontAwesomeIcon icon={faPeopleCarry} />
                                    </i>
                                </div>
                                <h4 className="title">
                                    Holistic Work Environment
                                </h4>
                                <p className="description">
                                    Sustainable companies that actively contribute
                                    back to the environment have shown evidence from studies by UCLA that their
                                    employees are typically more innovative, engaged and productive.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i>
                                        <FontAwesomeIcon
                                            icon={faHandshake}
                                        />
                                    </i>
                                </div>
                                <h4 className="title">Thriving Community</h4>
                                <p className="description">
                                With more people actively engaged in
                                sustainable actions that bring prosperity to all, everyone benefits!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default ResourcesOutcome;
