import React from 'react'
import styled from 'styled-components'
import { ReactComponent as EY } from '../../assets/images/home-page/logos/ey.svg'
import { ReactComponent as Deloitte } from '../../assets/images/home-page/logos/deloitte.svg'
import { ReactComponent as RBC } from '../../assets/images/home-page/logos/rbc.svg'
import { ReactComponent as KPMG } from '../../assets/images/home-page/logos/kpmg.svg'
import { ReactComponent as PWC } from '../../assets/images/home-page/logos/pwc.svg'
import { ReactComponent as Verizon } from '../../assets/images/home-page/logos/verizon.svg'
import { ReactComponent as CIBC } from '../../assets/images/home-page/logos/cibc.svg'
import { ReactComponent as RBS } from '../../assets/images/home-page/logos/rbs.svg'
import { ReactComponent as WP } from '../../assets/images/home-page/logos/wp.svg'
import { ReactComponent as JPM } from '../../assets/images/home-page/logos/jp-morgan.svg'
import { ReactComponent as CDW } from '../../assets/images/home-page/logos/cdw.svg'
import { ReactComponent as Cognizant } from '../../assets/images/home-page/logos/cognizant.svg'
import { ReactComponent as Intuit } from '../../assets/images/home-page/logos/intuit.svg'

// import { ReactComponent as Stripe } from "../LogoStripe1.svg"

const LogosStripe = () => {
    return (
        <StyledContainer className="logo-container">

            <LogoWrapper><EY /></LogoWrapper>
            <LogoWrapper><Deloitte /></LogoWrapper>
            <LogoWrapper><RBC /></LogoWrapper>
            <LogoWrapper><KPMG /></LogoWrapper>
            <LogoWrapper><PWC /></LogoWrapper>
            <LogoWrapper><Verizon /></LogoWrapper>
            {/* <Stripe /> */}
            <LogoWrapper><CIBC /></LogoWrapper>
            <LogoWrapper><RBS /></LogoWrapper>
            <LogoWrapper><WP /></LogoWrapper>
            <LogoWrapper><JPM /></LogoWrapper>
            <LogoWrapper><CDW /></LogoWrapper>
            <LogoWrapper><Cognizant /></LogoWrapper>
            <LogoWrapper><Intuit /></LogoWrapper>

        </StyledContainer>
    )
}

export default LogosStripe

const StyledContainer = styled.div`
display: inline-flex;
flex-direction: row;
white-space: nowrap;
// background-color: red;
`;

const LogoWrapper = styled.div`
display: inline-flex;
justify-content: center;
align-items: center;
vertical-align: middle;
padding: 0 2.5rem;
filter: grayscale(1) opacity(0.5);

&:hover{
    filter: grayscale(0);
}
`;