import React from 'react';
import styled from 'styled-components';
import PeopleHoldingGlobe from '../../assets/images/resources/PeopleHoldingGlobe.png';
import UnderlineTextBlock from '../textblocks/UnderlineTextBlock';


const ResourcesGoal = () => {
    return (
        <StyledContainer>
            <UnderlineTextBlock
                title="Our Goal"
                text="As an incubator that is passionate about sustainability, we aim to help develop companies who are sustainable, AND offer sustainability-related services."
                center={true}
                orange
            />
            <img
                style={{maxWidth: "35%", height: "auto"}}
                className="img-fluid"
                src={PeopleHoldingGlobe}
                alt=""
            />
        </StyledContainer>
    );
}

export default ResourcesGoal;

const StyledContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 2rem;
text-align: center;
padding-top: 60px;
padding-bottom: 60px;

h1{
    font-size: 1.25rem;
    font-weight: bold;
    text-align:center;
    margin-bottom: .75rem;
}

p{
    max-width: 40ch
}
`;
