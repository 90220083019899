import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookieConsentNotification = () => {
    return (
        <CookieConsent
            buttonText="I Understand"
            // debug={true}
            style={{
                background: "#494949",
                opacity: 0.8,
                alignItems: "center",
            }}
            buttonStyle={{
                background: "#e9e9e9",
                color: "#000e22",
                fontWeight: "bolder",
                height: "2.75em",
            }}
        >
            <span style={{ fontSize: "12px" }}>
                Abliminal uses cookies to improve your experience on our website and to analyze web traffic. <br />
                By using our website and our services, you agree to our use of cookies as described in our{" "}
                <Link to="" style={{ textDecoration: "underline" }}>
                    Terms of Service
                </Link>
                .
            </span>
        </CookieConsent>
    );
};

export default CookieConsentNotification;
