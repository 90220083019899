import {
    faLightbulb,
    faHandHoldingUsd,
    faLandmark,
    faChartPie,
} from "@fortawesome/free-solid-svg-icons";

export const apthenalStoriesData = [
    {
        industry: "Financial Services",
        problem: "Difficulty recruiting for cyber skills gap",
        outcome:
            "Cross training of IT team members to develop new skills in adjacent cyber domains. Improved retention of existing employees and reduction in key person concentration risk.",
        location: "Canada",
        img_src: faHandHoldingUsd,
    },
    {
        industry: "Private Equity",
        problem:
            "Integration challenges of new acquisitions including unforeseen IT and Cyber program costs.",
        outcome:
            "A repeatable process to map key themes through the due diligence process with expected investment implications. A feedback loop continually enhances the estimates for future projects.",
        location: "USA",
        img_src: faChartPie,
    },
    {
        industry: "Private Equity",
        problem:
            "Reactive, time intensive approach to managing risk across the portfolio.",
        outcome:
            "The ability to rapidly and consistently assess an entire portfolio of entities allowing the PE to focus their remediation efforts.",
        location: "USA",
        img_src: faChartPie,
    },
    {
        industry: "Financial Services",
        problem:
            "Difficulty in matching risk to multi-jurisdictional regulatory requirements.",
        outcome:
            "A visual and easy to use mapping of key risks to regulatory requirements to ensure the compliance and governance of global subsidiaries.",
        location: "Canada",
        img_src: faHandHoldingUsd,
    },
    {
        industry: "Private Equity",
        problem: "High volume, targeted attacks across the portfolio.",
        outcome:
            "Understanding of early warning signs of threats against portfolio assets. Identification and reduction in the public risk surface area.",
        location: "Canada",
        img_src: faChartPie,
    },
    {
        industry: "Private Equity",
        problem:
            "Challenges understanding differentiators between digital acquisition targets.",
        outcome:
            "Rapid insights on value indicators, differentiators, and investment implications. Ability to look at a large sample of targets and competitors to improve valuation models.",
        location: "USA",
        img_src: faChartPie,
    },
    {
        industry: "Private Equity",
        problem:
            "Low engagement of employees on cyber awareness and continued susceptibility to social engineering.",
        outcome:
            "Topical, engaging, and empowering training that created an embedded security culture.",
        location: "USA",
        img_src: faChartPie,
    },
    {
        industry: "Financial Services",
        problem: "Burden from source code revisions due to security issues.",
        outcome:
            "Broad scale fundamentals training enabling developers to build-in security by design.",
        location: "USA",
        img_src: faHandHoldingUsd,
    },
];

export const eyridStoriesData = [
    {
        industry: "Financial Services",
        problem: "Legacy privilege system was difficult to maintain.",
        outcome:
            "Needs assessment and migration to new solution. Custom connector development. Capability Augmentation.",
        location: "Canada",
        img_src: faHandHoldingUsd,
    },
    {
        industry: "Government",
        problem: "Difficulty in managing access to high privilege accounts.",
        outcome: "Configuration review, update, and capability enhancement.",
        location: "Canada",
        img_src: faLandmark,
    },
    {
        industry: "Private Equity",
        problem:
            "Difficulty in managing load of dynamic IAM environment with privileged users.",
        outcome:
            "Configuration review, simplification of onboarding and termination, capability enhancement, automated reporting and dashboarding.",
        location: "Canada",
        img_src: faChartPie,
    },

    {
        industry: "Financial Services",
        problem: "Capability gap in IAM ecosystem.",
        outcome:
            "Solution deployment and transition training for capability enhancement.",
        location: "Canada",
        img_src: faHandHoldingUsd,
    },
    {
        industry: "Power and Utility",
        problem:
            "Difficulty in managing load of dynamic IAM environment and required updates.",
        outcome:
            "Configuration review, update, and capability enhancement. Simplification of operating environment and knowledge transfer.",
        location: "Canada",
        img_src: faLightbulb,
    },
    {
        industry: "Financial Services",
        problem:
            "Internal infrastructure provisioning was at capabity and a staging environment was required for solution testing.",
        outcome:
            "Staging and Lab Environment deployed via cloud, preloaded with a mirrored environment to internal tooling and testing solutions.",
        location: "Canada",
        img_src: faHandHoldingUsd,
    },
    {
        industry: "Financial Services",
        problem:
            "Capacity and capability gap in meeting upgrade schedules for regulatory requirements.",
        outcome: "Configuration review, update, and capability enhancement.",
        location: "Canada",
        img_src: faHandHoldingUsd,
    },
];
