import React, { Component } from "react";
import ninjio from "../../assets/images/logos/ninjio.svg";
import gaEventHandler from "../../gaEventHandler";

export default class ApthenalCollaborators extends Component {
    render() {
        return (
            <section
                id="apthenal_collaborators"
                className="apthenal_collaborators"
            >
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 d-flex justify-content-center image-box align-items-stretch"></div>

                        <div
                            className="col-xl-6 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5 section-header"
                            style={{ textAlign: "center" }}
                        >
                            <h3>Our Collaborators</h3>
                            <span className="section-divider"></span>

                            <div className="logo">
                                <a
                                    href="https://ninjio.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={(event) => {
                                        gaEventHandler.handleClick(event);
                                    }}
                                >
                                    <img src={ninjio} alt="Ninjio" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
