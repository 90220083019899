import React from "react";
import styled from "styled-components";

const TextBlock = ({ title, text, center }) => {
  return (
    <Parent center={center}>
      <h1>{title}</h1>
      <p>{text}</p>
    </Parent>
  );
};

export default TextBlock;

const Parent = styled.div`
// font-family: "Rubik";
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  align-items: ${(props) => (props.center ? "center" : null)};
  text-align: ${(props) => (props.center ? "center" : null)};
  h1 {
    // margin: 0;
    max-width: 30ch;
  }
  p {
    max-width: 60ch;
    @media (max-width: 641px) {
      width: auto;
    }
  }
`;