import React, { Component } from "react";
import SustainabilityPolicy from "../../assets/images/resources/SustainabilityPolicyShadow.png";
import ContentSection from "../textblocks/ContentSection";
import SustainabilityPolicyPDF from "../../assets/files/AbliminalSustainabilityPolicy.pdf";

const ResourcesPolicy = () => {

      
  const body = () => {
    return (
        <>
            <p>
              To ensure that we have a cohesive and strategic approach, this is Abliminal's policy document that outlines our sustainable values and beliefs.
            </p>
            <p>
              We strive to instill similar policies within each company we help develop.
            </p>
        </>
    )
  };

  return (
    <ContentSection
      title="Our Policy"
      BodyText={body}
      buttonMessage="Download"
      buttonLink={SustainabilityPolicyPDF}
      download
      image={SustainabilityPolicy}
      orange/>
    );
}

export default ResourcesPolicy;