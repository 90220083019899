import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../components/Footer";
import BlogNavbar from "../components/hubspot-blog/BlogNavbar";
import Sidebar from "../components/Sidebar";
import Navigation from "../navigation/Navbar";
import NavigationSolid from "../navigation/NavbarSolid";
// import Navbar2 from "../../components/navbar/Navbar2";
// import Sidebar from "../../components/Sidebar";

const Layout = ({
    children,
    metaContent,
    metaCanonicalLink,
    pageTitle,
    hasFooter,
    blockHeaderTransition,
    logo,
    page,
    colorTheme,
}) => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={metaContent} />
                    <link rel="canonical" href={metaCanonicalLink} />
                </Helmet>
                <div className="d-flex" style={{ background: "#494949" }}>
                    {blockHeaderTransition ? <NavigationSolid /> : <Navigation /> }
                </div>
                <Sidebar />

                <div
                    className="align-items-center"
                    style={{ overflow: "hidden" }}
                >
                    {children}
                </div>
                {hasFooter ? <Footer /> : null}
            </HelmetProvider>
        </>
    );
};
export default Layout;
