import React from 'react'
import styled from 'styled-components'
import FoundOrgCard from '../cards/FoundOrgCard'
import TextBlock from '../textblocks/TextBlock'

const FO = () => {
    return (

        <StyledSection>

            <TextBlock
                title="We enable you to ideate, innovate, and build."
                text="Abliminal helps overcome the friction to invest in innovation. Whether
            you are an organzation that needs help to bring its internal IP to market,
            or founders looking for support to grow their offering - we have you covered."
                center={true}
            />

            <StyledContainer>
                {/* Hello World */}
                <FoundOrgCard variant="Founder" />
                <FoundOrgCard variant="Organization" />
            </StyledContainer>
        </StyledSection>

    )
}

export default FO

const StyledContainer = styled.div`
display: flex;
justify-content: space-evenly;
align-items: flex-start;
flex-wrap: wrap;
`;

const StyledSection = styled.section`
display: flex;
justify-contnet: center;
align-items: center;
flex-direction: column;
text-align: center;
p{
    max-width: 60ch;
}
`;