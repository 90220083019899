import React, { Component } from "react";
import Roadmap from "../../assets/images/challenge/ChallengeRoadmap.png";
import ContentSection from "../textblocks/ContentSection";

const ChallengeRoadmap = () => {

    const body = () => {
      return (
          <>
              <p>
                There will be three stages to this program, for more information, download our PDF below.
              </p>
          </>
      )
    };
  
    return (
      <ContentSection
      title="Roadmap"
      BodyText={body}
      image={Roadmap}
      rightAlign
      imageLarger/>
      );
}

export default ChallengeRoadmap;
