const axios = require("axios");

axios.default.defaults.baseURL =
    process.env.REACT_APP_BLOG_PROXY_DEV ||
    process.env.REACT_APP_BLOG_PROXY_STAGE;

export const fetcher = (url) => {
    axios.get(url).then((res) => res.data);
};

export const getAllBlogs = async () => {
    try {
        const blogs = await axios.get(`/blog`);
        return blogs.data;
    } catch (err) {
        console.log(err);
    }
};

export const getAllBlogTopics = async () => {
    try {
        const topics = await axios.get(`/blog/topics`);
        return topics.data;
    } catch (err) {
        console.log(err);
    }
};

export const getBlogTopicById = async (topicId) => {
    try {
        const topic = await axios.get(`/blog/topics/${topicId}`);
        return topic.data;
    } catch (err) {
        console.log(err);
    }
};

export const getAllBlogAuthors = async () => {
    try {
        const authors = await axios.get(`/blog/authors`);
        return authors.data;
    } catch (err) {
        console.log(err);
    }
};

export const getBlogAuthorById = async (authorId) => {
    try {
        const author = await axios.get(`/blog/authors/${authorId}`);
        return author.data;
    } catch (err) {
        console.log(err);
    }
};

export const getAllBlogPosts = async () => {
    try {
        const posts = await axios.get(`/blog/posts`);
        return posts.data;
    } catch (err) {
        console.log(err);
    }
};

export const getBlogById = async (blogid) => {
    try {
        const blog = await axios.get(`/blog/${blogid}`);
        return blog.data;
    } catch (err) {
        console.log(err);
    }
};

export const getPostsByBlogId = async (blogid) => {
    try {
        const posts = await axios.get(`/blog/${blogid}/posts`);
        return posts.data;
    } catch (err) {
        console.log(err);
    }
};

export const getPostById = async (postid) => {
    try {
        const post = await axios.get(`/blog/posts/id/${postid}`);
        return post.data;
    } catch (err) {
        console.log(err);
    }
};
