import React, { useState } from 'react'
import styled from 'styled-components'
import TextBlock from '../textblocks/TextBlock'
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import generic from "../../assets/videos/generic.mp4";
import { Link } from "react-router-dom";
import gaEventHandler from "../../gaEventHandler";
import { ReactComponent as HeroSVG } from "../../assets/images/hero-bg.svg"

const HeroNC = () => {
    const [show, setShow] = useState(false);

    return (
        // <section>
        <section id="hero">

            {/* <Hero /> */}
            <Centered>
                <TextBlock
                    title="Abliminal: Where Innovation Incubates"
                    text="Transform the problem into profit. Unleash your potential."
                    center={true}
                />
                <Button onClick={() => setShow(true)}>Watch Now</Button>

                {/* <Link
                    className="ourstory-btn"
                    to="#"
                    onClick={(event) => {
                        setShow(true);
                        gaEventHandler.handleClick(event);
                    }}
                >
                    Watch Our Story
                </Link> */}


                <VideoModal animation={true} centered={true} show={show} onHide={() => setShow(false)}>
                    <VideoPlayer
                        playing
                        muted={true}
                        controls={true}
                        url={generic}
                        onEnded={() => setShow(false)}
                        width="100%"
                    />
                </VideoModal>

            </Centered>
        </section>
    )
}

export default HeroNC

const Centered = styled.div`
height:100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
color: white;
z-index: 1;
flex-direction: column;
`;

const Button = styled.button`
background-color: black;
color: white;
padding: .5rem 3rem;
border: none;
border-radius: .5rem;
// margin: 10px 0px;
cursor: pointer;
z-index: 1;
transition: 0.5s;

&:hover{
    color: black;
    background-color: white;
}
`;

const Hero = styled(HeroSVG)`
width: 100%;
height: 100vh;
/* background: rgb(57,67,89);
background: linear-gradient(180deg, rgba(57,67,89,1) 0%, rgba(57,67,80,0) 50%); */
background: linear-gradient(
    to bottom,
    rgba(57, 67, 89, 1), 
    rgba(57, 67, 89, 0.8)
);
/* overflow: hidden; */
padding: 0;


    
    position: absolute;
    /* background: url(../img/backgrounds/hero-bg.svg) no-repeat center center
        fixed; */
    background: url(../img/backgrounds/hero-bg.svg) no-repeat center center;
    background-size: cover;
    /* z-index: -1; */
    pointer-events: none;

`;

const VideoModal = styled(Modal)`

    .modal-content {
        background: transparent;
        border: none;
        outline: none;
    }

    

    transition: all 600ms ease-in-out;
`;

const VideoPlayer = styled(ReactPlayer)`
    @media screen and (min-width: 481px) and (orientation: portrait) {
        video {
            transform: scale(1);
        }
    }
    @media screen and (min-width: 481px) and (orientation: landscape) {
        video {
            // transform: translate(0%, -30%) scale(1);
        }
    }
`;