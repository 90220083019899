import React, { Component } from "react";
import PDFIcon from "../../assets/images/challenge/ChallengePDF.png";
import ContentSection from "../textblocks/ContentSection";
import { useMediaQuery } from 'react-responsive';
import sustainabilityOverview from "../../assets/files/S22SolutionsChallengeResource.pdf";

const ChallengeResource = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

  const body = () => {
    return (
        <>
          <ul style={isMobile ? {listStyleType: "none"} : {}}>
              <li>
                  No more time constraints! We will make accommodations for you, if needed.
              </li>
              <li>
                  Mentorship, networking opportunities and resources!
              </li>
              <li>
                  A safe space for everyone involved!
              </li>
              <li>
                  Free to register and participate!
              </li>
          </ul>
          <h3 className="section_header" >Get Started</h3>
          <hr className="black_underline" width="25%" size="4"></hr>
          <p>
            The best time to start was yesterday, the second best time is now. Start your proposal by following this simple guide!
          </p>
        </>
    )
  };

  return (
      <ContentSection
        title="Why Participate?"
        BodyText={body}
        buttonMessage="Download"
        buttonLink={sustainabilityOverview}
        download
        image={PDFIcon}/>
  );

}

export default ChallengeResource;