import React from 'react'
import styled from 'styled-components'
import TextBlock from '../textblocks/TextBlock'


const Painpoints = () => {
    const FPAINPOINTS = [
        "Lack of market team, no go-to-market strategy",
        "funding",
        "development support",
        "knowledge of next steps",
        "sales support",
        "data and insights"
    ]

    const OPAINPOINTS = [
        "Lack Resources (People, Time and Money)",
        "Viewed as a cost centre (Innovation is a high-risk investment)",
        "Temporary Fixes (short-term solutions are more palatable)",
    ]

    // cibst listItems = FPAINPOINTS.map()

    return (

        <StyledSection>
            <TextBlock
                title="We understand the pain points when it comes to innovation!"
                center={true}
            />
            <StyledContainer>
                <CardContainer>
                    <h1>Founder Painpoints</h1>
                    <ul>
                        {FPAINPOINTS.map((point, index) =>
                            <li key={index}>
                                {point}
                            </li>
                        )}
                    </ul>
                </CardContainer>
                <CardContainer>
                    <h1>Organization Painpoints</h1>
                    <ul>
                        {OPAINPOINTS.map((point, index) =>
                            <li key={index}>
                                {point}
                            </li>
                        )}
                    </ul>
                </CardContainer>
            </StyledContainer>
        </StyledSection>



    )
}

export default Painpoints

const StyledSection = styled.section`
background-color: #F2F2F7;
// padding: 2rem 0;
`;

const CardContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
padding: 2rem 0;
h1{
        font-size: 1.25rem;
        font-weight: bold;
        text-align:center;
        margin-bottom: .75rem;
}
li{
    list-style-type: circle;
    max-width: 40ch
}
`;

const StyledContainer = styled.div`
display: flex;
justify-content: space-evenly;
align-items: flex-start;
flex-wrap: wrap;
// padding: 2rem 0;

`;