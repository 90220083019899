import React from "react";
// import Collaborators from "../components/Collaborators";
// import Navbar from "../navigation/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ChallengeLanding from "../components/Challenge/ChallengeLanding";
import ChallengeMission from "../components/Challenge/ChallengeMission";
import ChallengeProgramStructure from "../components/Challenge/ChallengeProgramStructure";
import ChallengeRoadmap from "../components/Challenge/ChallengeRoadmap";
import ChallengeResource from "../components/Challenge/ChallengeResource";
import RequestDemo from "../components/RequestDemo";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../hocs/Layout";
import Logo from "../assets/images/logos/NavLogo2.svg";
import ChallengeRegister from "../components/Challenge/ChallengeRegister";

const Challenge = () => {
    const metaContent = `Creating opportunities for female neurodivergent youth who have sustainable solutions to change the world!`;
    const canonicalLink = `http://www.abliminal.com/challenge`;

    return (
        <>
            <Layout
                hasFooter
                pageTitle={`Abliminal Solutions Challenge`}
                logo={Logo}
                metaContent={metaContent}
                metaCanonicalLink={canonicalLink}
                page="challenge"
            >
                <ChallengeLanding />
                <ChallengeMission />
                <ChallengeProgramStructure />
                <ChallengeRoadmap />
                <ChallengeResource />
                <ChallengeRegister />
            </Layout>
        </>
    );
};

export default Challenge;
