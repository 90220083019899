import React from "react";
// import Collaborators from "../components/Collaborators";
// import Navbar from "../navigation/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ResourcesLanding from "../components/Resources/ResourcesLanding";
import ResourcesGoal from "../components/Resources/ResourcesGoal";
import ChallengeProgramStructure from "../components/Challenge/ChallengeProgramStructure";
import ChallengeRoadmap from "../components/Challenge/ChallengeRoadmap";
import ResourcesPolicy from "../components/Resources/ResourcesPolicy";
import ResourcesProgress from "../components/Resources/ResourceProgress";
import ResourcesOutcome from "../components/Resources/ResourcesOutcome";
import ResourcesRoadmap from "../components/Resources/ResourcesRoadmap";
import RequestDemo from "../components/RequestDemo";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../hocs/Layout";
import Logo from "../assets/images/logos/NavLogo2.svg";
import ChallengeRegister from "../components/Challenge/ChallengeRegister";

const Resources = () => {
    const metaContent = `As an incubator that is passionate about sustainability, we aim to help develop companies who are sustainable.`;
    const canonicalLink = `http://www.abliminal.com/resources`;

    return (
        <>
            <Layout
                hasFooter
                blockHeaderTransition
                pageTitle={`Abliminal Sustainability Resources`}
                logo={Logo}
                metaContent={metaContent}
                metaCanonicalLink={canonicalLink}
                page="sustainability resources"
            >
                <ResourcesLanding />
                <ResourcesGoal />
                <ResourcesPolicy />
                <ResourcesProgress />
                <ResourcesRoadmap />
                <ResourcesOutcome />
            </Layout>
        </>
    );
};

export default Resources;
