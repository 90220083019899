import React, { useCallback, useEffect, useState } from "react";
import HamburgerMenu from "react-hamburger-menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { blogTopicData, blogIndustriesData } from "../../data/NavData";
import BlogSearchbar from "./BlogSearchbar";
import { useViewport } from "../../hooks/useViewport";
import {
    Button,
    Form,
    FormControl,
    Nav,
    Navbar,
    NavDropdown,
} from "react-bootstrap";

const BlogNavbar = () => {
    const { width } = useViewport();
    const breakpoint = 768;
    const [burgerOpen, setBurgerOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(-1);
    const itemLabels = ["Topics", "Industries"];

    const handleToggle = (position) => {
        setDropdownOpen(position);
    };

    const handleBurgerToggle = () => {
        setBurgerOpen(!burgerOpen);
    };

    const handleClose = () => {
        setDropdownOpen(-1);
    };

    const onClickOutsideListener = () => {
        handleClose();
        document.removeEventListener("click", onClickOutsideListener);
    };

    const onEscapeKey = useCallback((event) => {
        if (event.keyCode === 27) {
            handleClose();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", onEscapeKey, false);
        return () => {
            document.removeEventListener("keydown", onEscapeKey, false);
        };
    }, []);

    const BlogNavLink = ({ label, position }) => {
        return (
            <a
                href="#"
                className="blog-sidebar-label col-auto"
                onClick={() => {
                    // console.log(position);
                    handleToggle(position);

                    if (dropdownOpen === position) {
                        handleClose();
                    }
                }}
            >
                <h6
                    className="blog-nav-link"
                    style={{
                        color: dropdownOpen === position ? "coral" : "#fff",
                    }}
                >
                    <b>
                        {label}
                        {dropdownOpen === position ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </b>
                </h6>
            </a>
        );
    };

    const Dropdown = ({ navObj, position }) => {
        return (
            <ul
                className={`collapsed ${
                    dropdownOpen === position ? "is-expanded" : ""
                }`}
                onMouseLeave={() => {
                    document.addEventListener("click", onClickOutsideListener);
                }}
            >
                <a
                    href="#"
                    onClick={() => {
                        setDropdownOpen(-1);
                    }}
                    className="d-flex justify-content-end my-2"
                >
                    <CloseIcon />
                </a>
                <div className="blog-nav-wrapper">
                    {navObj.map((item, index) => {
                        return (
                            <li>
                                <Link to={item.link}>{item.title}</Link>
                            </li>
                        );
                    })}
                </div>
            </ul>
        );
    };

    const BurgerMenu = () => {
        return (
            <ul
                className={`collapsed ${burgerOpen ? "is-expanded" : ""}`}
                onMouseLeave={() => {
                    document.addEventListener("click", onClickOutsideListener);
                }}
            >
                <div>
                    <BlogSearchbar />
                </div>
                {itemLabels.map((item, i) => {
                    return (
                        <>
                            <BlogNavLink label={item} position={i} />

                            {itemLabels.map((item, i) => {
                                switch (i) {
                                    case 0:
                                        return (
                                            <Dropdown
                                                navObj={blogTopicData}
                                                position={i}
                                            />
                                        );
                                    case 1:
                                        return (
                                            <div>
                                                <Dropdown
                                                    navObj={blogIndustriesData}
                                                    position={i}
                                                />
                                            </div>
                                        );
                                    default:
                                        return "";
                                }
                            })}
                        </>
                    );
                })}
            </ul>
        );
    };

    const DesktopNav = () => {
        return (
            <>
                {itemLabels.map((item, i) => {
                    return <BlogNavLink label={item} position={i} />;
                })}
                <BlogSearchbar />
            </>
        );
    };

    const MobileNav = () => {
        return (
            <Navbar expand="lg">
                <div
                    className="row justify-content-between"
                    style={{ width: "100vw" }}
                >
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <Navbar.Toggle aria-controls="basic-navbar-nav">
                                <HamburgerMenu
                                    height={15}
                                    width={20}
                                    strokeWidth={2}
                                    color="#c9c9c9"
                                />
                            </Navbar.Toggle>
                            <BlogSearchbar />
                        </div>
                    </div>
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {itemLabels.map((item, i) => {
                            let navObj = null;

                            switch (i) {
                                case 0:
                                    navObj = blogTopicData;
                                    break;
                                case 1:
                                    navObj = blogIndustriesData;
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <>
                                    <NavDropdown
                                        title={item}
                                        id="basic-nav-dropdown"
                                    >
                                        {navObj?.map((item, i) => {
                                            return (
                                                <NavDropdown.Item
                                                    as={Link}
                                                    to={item.link}
                                                >
                                                    {item.title}
                                                </NavDropdown.Item>
                                            );
                                        })}
                                    </NavDropdown>
                                </>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    };

    return (
        <Navigation>
            <nav className="nav">
                <div className="container">
                    <div className="row justify-content-center">
                        {width < breakpoint ? <MobileNav /> : <DesktopNav />}

                        {/* Desktop / tablet
       {itemLabels.map((item, i) => {
                            return <BlogNavLink label={item} position={i} />;
                        })}

                  */}

                        {width > breakpoint
                            ? itemLabels.map((item, i) => {
                                  switch (i) {
                                      case 0:
                                          return (
                                              <Dropdown
                                                  navObj={blogTopicData}
                                                  position={i}
                                              />
                                          );
                                      case 1:
                                          return (
                                              <Dropdown
                                                  navObj={blogIndustriesData}
                                                  position={i}
                                              />
                                          );
                                      default:
                                          return "";
                                  }
                              })
                            : ""}
                    </div>
                </div>
            </nav>
        </Navigation>
    );
};

export default BlogNavbar;

const Navigation = styled.header`
    // height: auto;
    min-height: 50px;
    position: relative;
    color: #fff;
    background: transparent;
    z-index: 2;

    ul.collapsed {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;
        max-height: 0;
        transition-duration: 0.5s;
        background: rgba(0, 0, 0, 0.4);

        &.is-expanded {
            overflow: hidden;
            max-height: 550px; /* approximate max height */
            transition-duration: 0.5s;
            margin: 3em 0em;
        }
        li {
            padding: 15px 10px;
            margin: 0px 0px;
            width: 100%;
        }
    }

    nav {
        ul {
            display: flex;
            justify-content: space-between;
            list-style-type: none;
        }
        li {
            margin: 0 25px;
            justify-content: space-between;
            font-size: 1.25em;
        }
        a {
            font-size: 0.85em;
            font-weight: bold;
            text-decoration: none;
            color: #fff;

            .active {
                color: tomato;
            }
        }
        a:hover {
            color: coral;
            transition: all 200ms;
        }

        .blog-nav-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            padding: 20px 10px;
            width: 100%;
            text-align: left;
        }

        @media (max-width: 767px) {
            .blog-nav-wrapper {
                display: inline-block;
            }
        }
    }
`;
