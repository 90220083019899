import React, { Component } from "react";
import powerutil from "../../assets/images/powerutil3.svg";
import finserv from "../../assets/images/financial.svg";
import government from "../../assets/images/government.svg";
import pequity from "../../assets/images/private-equity.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLightbulb,
    faHandHoldingUsd,
    faLandmark,
    faChartPie,
} from "@fortawesome/free-solid-svg-icons";

export default class Industries extends Component {
    industries_data = [
        {
            industry: "Power & Utilities",
            description: "Making the connections between critical technologies",
            img_src: powerutil,
            id: "powerutil-image",
            alt: "power",
            icon: faLightbulb,
        },
        {
            industry: "Financial Services",
            description:
                "Giving your clients greater access to valuable services",
            img_src: finserv,
            id: "finserv-image",
            alt: "financial services",
            icon: faHandHoldingUsd,
        },
        {
            industry: "Governments",
            description: "Supporting the shared services model",
            img_src: government,
            id: "government-image",
            alt: "government",
            icon: faLandmark,
        },
        {
            industry: "Private Equity",
            description: "Enabling your portfolio for success",
            img_src: pequity,
            id: "pequity-image",
            alt: "private equity",
            icon: faChartPie,
        },
    ];

    render() {
        return (
            <section id="industries" className="industries">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 d-flex justify-content-center image-box align-items-stretch"></div>

                        <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <h3>Serving you across different industries</h3>
                            <p>
                                Our solutions support clients from all kinds of
                                companies.
                            </p>

                            {this.industries_data.map((item, index) => {
                                return (
                                    <div className="icon-box" key={index}>
                                        <div className="icon">
                                            <FontAwesomeIcon
                                                icon={item.icon}
                                                size="2x"
                                            />
                                        </div>
                                        <h4 className="title">
                                            {item.industry}{" "}
                                        </h4>
                                        <p className="description">
                                            {item.description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
