import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

export const navData = [
    // {
    //     title: "Home",
    //     icon: "",
    //     link: "/",
    //     iconClosed: "",
    //     iconOpened: "",
    //     subNav: [],
    // },
    {
        title: "Solutions",
        icon: "",
        link: "#",
        iconClosed: <ArrowDropDownIcon />,
        iconOpened: <ArrowDropUpIcon />,
        subNav: [
            {
                title: "Apthenal",
                icon: "",
                link: "/apthenal",
            },
            {
                title: "EyrID",
                icon: "",
                link: "https://eyrid.com/",
            },
        ],
    },    {
        title: "Sustainability",
        icon: "",
        link: "#",
        iconClosed: <ArrowDropDownIcon />,
        iconOpened: <ArrowDropUpIcon />,
        subNav: [
            {
                title: "Solutions Challenge",
                icon: "",
                link: "/challenge",
            },
            {
                title: "Sustainability Resources",
                icon: "",
                link: "/resources",
            },
        ],
    },

    // {
    //     title: "Blog",
    //     icon: "",
    //     link: "/blog",
    //     iconClosed: "",
    //     iconOpened: "",
    //     subNav: [],
    // },
    // {
    //     title: "Resources",
    //     icon: "",
    //     link: "#",
    //     iconClosed: <ArrowDropDownIcon />,
    //     iconOpened: <ArrowDropUpIcon />,
    //     subNav: [
    //         {
    //             title: "Documentation",
    //             icon: "",
    //             link: "/underconstruction",
    //         },
    //         {
    //             title: "Customer Stories",
    //             icon: "",
    //             link: "/underconstruction",
    //         },
    //     ],
    // },
];

export const blogTopicData = [
    {
        title: "Abliminal",
        icon: "",
        link: "/blog/category/abliminal",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },
    {
        title: "Cybersecurity",
        icon: "",
        link: "/blog/category/cybersecurity",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },
    {
        title: "Technology",
        icon: "",
        link: "/blog/category/technology",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },

    {
        title: "Environment",
        icon: "",
        link: "/blog/category/environment",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },
];

export const blogIndustriesData = [
    {
        title: "Financial Services",
        icon: "",
        link: "/blog/category/financialservices",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },
    {
        title: "Private Equity",
        icon: "",
        link: "/blog/category/privateequity",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },
    {
        title: "Power and Utilities",
        icon: "",
        link: "/blog/category/powerandutilities",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },
    {
        title: "Public Sector and Government",
        icon: "",
        link: "/blog/category/publicsectorandgovernment",
        iconClosed: "",
        iconOpened: "",
        subNav: [],
    },
];
