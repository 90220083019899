import React, { Component } from "react";
import { useMediaQuery } from 'react-responsive';

const ContentSection = ({ title, BodyText, buttonMessage, buttonLink, download, image, imageLarger, rightAlign, orange }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 992px)` });

    const Graphic = () => {
        return (
        <div style={isMobile || !imageLarger ? {} : {width: "70%"}} className="col-lg-5 d-flex justify-content-center">
            <div className="home_left_img">
                {console.log(imageLarger)}
                <img style={imageLarger ? {} : {maxWidth: "400px"}} className="img-fluid" src={image} alt="" />
            </div>
        </div>
        );
    };

    const Text = () => {
        return (

            <div  style={isMobile || !imageLarger ? {} : {width: "30%"}}  className="section-title col-lg-6 align-items-stretch justify-content-center py-5 px-lg-5">
                <div style={isMobile ? {alignItems: "center", textAlign: "center"} : {}} className="d-flex flex-column">
                    <h3 className="section_header" >{title}</h3>
                    <hr className={orange ? "orange_underline" : "black_underline"} width="25%" size="4"></hr>
                    
                    {BodyText ? <BodyText/> : <></>}
                </div>
                {buttonMessage ?
                    <a href={buttonLink} download={download}>
                        <div className={isMobile ? "text-center" : ""}>
                            <button className={`sustainability_button ${orange ? "sustainability_button_orange" : "sustainability_button_black"}`}>
                                {buttonMessage}
                            </button>
                        </div>
                    </a>
                : <></>}

            </div>
        );
    };

    return (
      <section style={isMobile ? {paddingBottom: "0px", paddingTop: "50px"} : {paddingTop: "100px", paddingBottom: "0px"}} id="challenge-resource" className="challenge-resource">
        <div className="container">
          <div className="row ">
          {isMobile || !rightAlign ?
            <>
                <Text/>
                <Graphic/> 
            </>
            :
            <>
                <Graphic/>
                <Text/>
            </>
            }
          </div>
        </div>
      </section>
    );
}

export default ContentSection;