import React, { Component } from "react";
import SustainabilityProgress from "../../assets/images/resources/SustainabilityProgress.png";
import ContentSection from "../textblocks/ContentSection";
import progressReport from "../../assets/files/AbliminalSustainabilityReport.pdf";

const ResourcesProgress = () => {
    
    const body = () => {
        return (
            <>
                <p>
                    We have since developed other companies who all share the same vision as we do.
                </p>
                <p>
                    Download our Sustainability Report today and learn how we are contributing to sustainability as a team and as a company.
                </p>
            </>
        )
    };

    return (
        <ContentSection
            title="Our Progress"
            BodyText={body}
            buttonMessage="Download"
            buttonLink={progressReport}
            download
            image={SustainabilityProgress}
            rightAlign
            orange/>
        );
}

export default ResourcesProgress;
