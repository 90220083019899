import React from "react";

const PrivacyTOSNav = () => (
  <ul className="nav">
    <li className="nav-item">
      <h4>
        <a
          className="nav-link"
          href="/legal/terms-of-service"
          style={{ color: "#c9c9c9" }}
        >
          Terms of Service
        </a>
      </h4>
    </li>
    <li className="nav-item">
      <h4>
        <a
          className="nav-link"
          href="/legal/privacy-policy"
          style={{ color: "#c9c9c9" }}
        >
          Privacy Policy
        </a>
      </h4>
    </li>
  </ul>
);

export default PrivacyTOSNav;
