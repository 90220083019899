import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default class SuccessStoriesCarousel extends Component {
    state = {
        responsive: {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 3,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 2,
            },
            tablet: {
                breakpoint: { max: 1023, min: 464 },
                items: 1,
            },
            mobile: {
                breakpoint: { max: 480, min: 0 },
                items: 1,
            },
        },
    };

    render() {
        return (
            <section id="stories-carousel" className="stories-carousel">
                <div className="container">
                    <Carousel
                        responsive={this.state.responsive}
                        infinite
                        autoPlaySpeed={12000}
                        showDots
                        keyBoardControl
                        draggable
                        swipeable
                        customTransition="all .5s"
                        arrows={false}
                        centerMode={false}
                        additionalTransfrom={0}
                    >
                        {this.props.data.map((item, index) => {
                            return (
                                <div className="card" key={index}>
                                    <div className="card-body">
                                        <h4
                                            className="card-title"
                                            style={{ color: "#e43c5c" }}
                                        >
                                            Client{" "}
                                        </h4>
                                        <p> {item.industry}</p>

                                        <h4 style={{ color: "#ff6347" }}>
                                            Problem
                                        </h4>
                                        <p>{item.problem}</p>
                                        <h4 style={{ color: "#009970" }}>
                                            Outcome
                                        </h4>
                                        <p>{item.outcome}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </section>
        );
    }
}
