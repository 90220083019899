import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";

import axios from "axios";

axios.default.defaults.baseURL =
    process.env.REACT_APP_BLOG_PROXY_DEV ||
    process.env.REACT_APP_BLOG_PROXY_STAGE;

const fetcher = (url) => axios.get(url).then((res) => res.data);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            {/* Provide swr fetcher globally */}
            <SWRConfig
                value={{
                    fetcher,
                }}
            >
                <App />
            </SWRConfig>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
