import React, { Component } from "react";
import mars_logo from "../assets/images/logos/mars_logo.svg";
import cresciv_logo from "../assets/images/logos/cresciv.svg";
import gaEventHandler from "../gaEventHandler";

export default class Supporters extends Component {
    render() {
        return (
            <section className="supporters section-bg-2">
                <div className="container">
                    <div className="row">
                        <div
                            className="section-header wow fadeIn"
                            data-wow-duration="1s"
                        >
                            <h3 className="section-title">
                                Supporting us along the way
                            </h3>
                            <span className="section-divider"></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="logo col-md-6 align-items-stretch justify-content-around">
                            <a
                                href="https://www.marsdd.com"
                                target="_blank"
                                rel="noreferrer"
                                onClick={(event) => {
                                    gaEventHandler.handleClick(event);
                                }}
                            >
                                <img src={mars_logo} alt="Mars Logo" />
                            </a>
                        </div>
                        <div className="logo col-md-6 align-items-stretch justify-content-around">
                            <a
                                href="http://cresciv.com"
                                target="_blank"
                                rel="noreferrer"
                                onClick={(event) => {
                                    gaEventHandler.handleClick(event);
                                }}
                            >
                                <img src={cresciv_logo} alt="Cresciv Logo" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
