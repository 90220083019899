import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

// import { getTOS } from "../utils/api/legal";
import PrivacyTOSNav from "../../components/PrivacyTOSNav";
import { tos } from "../../data/temp-tos";
import Layout from "../../hocs/Layout";

const TermsOfService = () => {
  const [data, setData] = useState([]);

  // getTOS();
  useEffect(() => {
    if (data) {
      const data = tos;

      setData(data);
    }

    console.log(data);
  }, [tos]);

  const HeaderLink = ({ dest, index }) => (
    <Link
      to={`${dest}`}
      spy={true}
      smooth={true}
      offset={-100}
      duration={300}
      className="headerLink"
    >
      <span className="headerLinkText">{`${index + 1}. ${dest}`}</span>
    </Link>
  );

  return (
    <Layout hasFooter pageTitle={`EyrID - Terms of Service`} colorTheme="dark">
      <div className="container pt-5 mt-5">
        <h2>
          <b>Terms of Service</b>
        </h2>

        <PrivacyTOSNav />
        <div>
          {data?.map((item, index) => {
            return (
              <h5>
                <HeaderLink dest={item.section} index={index} />
              </h5>
            );
          })}
        </div>

        <div className="tos-section pt-5">
          {data?.map((item, index) => {
            if (item?.type !== "list") {
              return (
                <div className="mainSection pt-4">
                  <h4 id={`${item.section}`}>{`${index + 1}. ${
                    item.section
                  }`}</h4>
                  <p className="ml-5 mr-5">{item?.content}</p>
                </div>
              );
            }

            if (item?.type === "list") {
              return (
                <div className="mainSection pt-4">
                  <h4 id={`${item.section}`}>{`${index + 1}. ${
                    item.section
                  }`}</h4>

                  {item?.content.map((item, i) => {
                    return (
                      <>
                        <h6 className="list-header ml-4 mr-4 pt-2">
                          {item.listHeader}
                        </h6>
                        <p className="ml-5 mr-5">{item.listContent.points}</p>
                      </>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfService;
