import { faIdBadge, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import gaEventHandler from "../../gaEventHandler";

export default class Solutions extends Component {
    solutionsData = [
        {
            link: "/apthenal",
            title: "Apthenal",
            description:
                "Assess Risk, Improve Decision Making, Train your employees",
            link_desc: "Discover Apthenal",
            id: "apthenal-box",
            video_id: "apthenal-video",
            icon: faProjectDiagram,
        },
        {
            link: "/eyrid",
            title: "EyrID",
            description:
                "Credential Protection, Controlled Data Sharing, Identity Unification",
            link_desc: "Discover EyrID",
            id: "eryid-box",
            video_id: "eyrid-video",
            icon: faIdBadge,
        },
    ];

    render() {
        return (
            <section id="solutions" className="solutions section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="row no-gutters">
                        <div className="content col-xl-5 d-flex align-items-stretch">
                            <div className="content">
                                <h3 style={{ color: "lightgrey" }}>Our Solutions </h3>
                                <br />
                                <p style={{ color: "lightgrey" }}>
                                    Through our platforms equipping you with
                                    data driven decisions and resolutions, we
                                    provide you with insights, training, and the
                                    heavy-lifting for your business to become
                                    the future.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-7 d-flex align-items-stretch">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    {this.solutionsData.map((item, index) => {
                                        return (
                                            <div
                                                className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0"
                                                key={index}
                                            >
                                                <div
                                                    className="icon-box"
                                                    data-aos="fade-up"
                                                    data-aos-delay="100"
                                                >
                                                    <div className="icon">
                                                        <i>
                                                            <FontAwesomeIcon
                                                                icon={item.icon}
                                                                size="sm"
                                                            />
                                                        </i>
                                                    </div>
                                                    <h4 className="title">
                                                        {item.title}
                                                    </h4>
                                                    <p className="description">
                                                        {item.description}
                                                    </p>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <a
                                                            href={item.link}
                                                            className="btn-main"
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                gaEventHandler.handleClick(
                                                                    event
                                                                );
                                                            }}
                                                        >
                                                            Learn More
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
