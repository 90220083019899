import React from 'react'
import styled from 'styled-components'
// import CardCircle from '../cards/CardCircle'
import LocationCard from '../cards/LocationCard'
import TextBlock from '../textblocks/TextBlock'
import { ReactComponent as Globe } from '../../assets/images/home-page/globe.svg'

const Locations = () => {
    return (

        <StyledSection>
            <TextBlock
                title="Creating solutions around the globe"
                text="Global Insights, Local Team. We problem solve together at our locations 
                around the world - Canada, USA, UK, and Australia."
                center={true}
            />

            {/* <TextBlock
                title="From around the world"
                center={true}
            /> */}
            <StyledContainer>
                <LocationCard type="Canada" />
                <LocationCard type="USA" />
                <LocationCard type="UK" />
                <LocationCard type="Australia" />


            </StyledContainer>

            <GlobeContainer>
                <Globe />
            </GlobeContainer>
        </StyledSection>

    )
}

export default Locations

const StyledSection = styled.div`
display: grid;
place-items: center;
`;

const StyledContainer = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;

@media (max-width: 768px) {
    flex-direction: column;
  }
`;

const GlobeContainer = styled.div`
display: grid;
place-items: center;
height: 80%;
width: 100%;
`;