import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Mars } from '../../assets/images/home-page/MaRs.svg'
import { ReactComponent as Cresciv } from '../../assets/images/home-page/Cresciv.svg'
import TextBlock from '../textblocks/TextBlock'

import gaEventHandler from '../../gaEventHandler'

const Support = () => {
    return (
        <StyledSection>
            <TextBlock
                title="Support and expertise from"
            />
            <LogoContainer>
                <LogoWrapper>
                    <a
                        href="https://www.marsdd.com"
                        target="_blank"
                        rel="noreferrer"
                        onClick={(event) => {
                            gaEventHandler.handleClick(event);
                        }}
                    >
                        <Mars />
                    </a>
                </LogoWrapper>

                <LogoWrapper>
                    <a
                        href="http://cresciv.com"
                        target="_blank"
                        rel="noreferrer"
                        onClick={(event) => {
                            gaEventHandler.handleClick(event);
                        }}
                    >
                        <Cresciv />
                    </a>

                </LogoWrapper>

            </LogoContainer>
        </StyledSection>

    )
}

export default Support

const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    place-items:center;
    text-align:center;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
 `;

const LogoWrapper = styled.div`
    padding: 3rem;
    filter: grayscale(1) opacity(0.5);
    &:hover{
        filter: grayscale(0);
    }

`;