import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Apthenal from "./pages/Apthenal";
import Challenge from "./pages/Challenge";
import Resources from "./pages/Resources";
// import Eyrid from "./pages/Eyrid";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/legal/Privacy-Policy";
import TermsOfService from "./pages/legal/TermsOfService";
import Blog from "./pages/blog/Blog";
import Post from "./pages/blog/Post";
// import Category from "./components/blog/Category";
// import BlogDetail from "./components/blog/BlogDetail";

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/apthenal" component={Apthenal} />
            <Route exact path="/challenge" component={Challenge} />
            <Route exact path="/resources" component={Resources} />
            <Route
                exact
                path="/legal/terms-of-service"
                component={TermsOfService}
            />
            <Route
                exact
                path="/legal/privacy-policy"
                component={PrivacyPolicy}
            />
            {/* <Route path="/blog/posts/:queryType?/:value?" component={Blog} />
            <Route
                path="/blog/post/:queryType?/:value?/id/:postId"
                component={Post}
            />

            <Redirect
                from={{ pathname: "/blog" }}
                to={{ pathname: "/blog/posts" }}
            /> */}
            <Redirect to={{ pathname: "/" }} />
        </Switch>
    );
};
export default Routes;
