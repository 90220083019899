import React, { Component } from "react";
import { Link as ReactScroll } from "react-scroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import apthenalscreenmain from "../../assets/images/apthenalscreen-main.png";

export default class ApthenalLanding extends Component {
    render() {
        return (
            <section className="home_banner_area">
                <div className="banner_inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="banner_content ">
                                    <h1 className="animate__animated animate__fadeInDown">
                                        Apthenal
                                    </h1>
                                    <ul className="animate__animated animate__flipInX">
                                        <li>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                            />{" "}
                                            Collect unique insights
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                            />{" "}
                                            Build your portfolio
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                            />{" "}
                                            Train your organization
                                        </li>
                                    </ul>
                                    <ReactScroll
                                        to="apthenal_collaborators"
                                        className="btn-main"
                                        smooth={true}
                                        duration={400}
                                    >
                                        Learn More
                                    </ReactScroll>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="home_left_img">
                                    <img
                                        className="img-fluid"
                                        src={apthenalscreenmain}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
