import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 0;
    list-style: none;
    height: 80px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bolder;

    &:hover {
        background: rgba(50, 53, 66, 0.5);
        border-left: 4px solid #c2c2c2;
        cursor: pointer;
    }

    &:active {
        text-decoration: none;
    }

    @media (min-device-width: 360px) and (max-device-width: 812px) {
        padding: 0 0.5em;
        font-size: 16px;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 1rem;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    padding: 0 1em;

    @media (min-device-width: 360px) and (max-device-width: 812px) {
        margin-left: 0rem;
    }
`;

const SidebarIcon = styled.span`
    padding: 0 0;
`;

const DropdownLink = styled(Link)`
    background: rgba(50, 53, 66, 0.5);
    height: 60px;
    padding: 0 0em;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 15px;
    font-weight: normal;

    &:hover {
        background: #414757;
        cursor: pointer;
        font-weight: bold;
    }

    @media (min-device-width: 360px) and (max-device-width: 812px) {
        height: 50px;
        justify-content: left;
        padding-left: 2.25rem;
        font-size: 13px;
    }
`;

const SubMenu = ({ item }) => {
    //set to false for collapsed default
    const [subNav, setSubNav] = useState(true);
    const showSubNav = () => setSubNav(!subNav);

    return (
        <>
            <SidebarLink
                className="sidebar-links"
                to={item.link}
                onClick={item.subNav && showSubNav}
            >
                <div>
                    <SidebarIcon>{item.icon}</SidebarIcon>
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subNav
                        ? item.iconOpened
                        : item.subNav
                            ? item.iconClosed
                            : null}
                </div>
            </SidebarLink>
            {subNav &&
                item.subNav.map((item, index) => {
                    return (
                        <DropdownLink
                            to={{ pathname: item.link }}
                            target="_blank"
                            key={index}
                        >
                            {item.icon}
                            <SidebarLabel>{item.title}</SidebarLabel>
                        </DropdownLink>
                    );
                })}
        </>
    );
};

export default SubMenu;
