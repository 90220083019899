import React from 'react'
import styled from 'styled-components'
import CardCircle from '../cards/CardCircle'
import TextBlock from '../textblocks/TextBlock'

const Arrow = () => {
    return (
        <svg className="arrow" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0943 6.90572L34.7742 20L12.0943 33.0942L12.0943 6.90572Z" fill="#111E44" />
        </svg>
    )
}

const Steps = () => {
    return (
        <section>
            <TextBlock
                title="Innovation: Step by Step"
                text="Abliminal has mapped out the steps to help launch your solution."
                center={true}
            />
            <StyledContainer>
                <CardCircle type="Insights"></CardCircle>
                <Arrow className="arrow" />
                <CardCircle type="Investment"></CardCircle>
                <Arrow className="arrow" />
                <CardCircle type="Innovation"></CardCircle>
                <Arrow className="arrow" />
                <CardCircle type="Income"></CardCircle>
            </StyledContainer>
        </section>

    )
}

export default Steps

const StyledContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

@media (max-width: 768px) {
    flex-direction: column;

.arrow{
    transform: rotate(90deg);
}
  }
`;